/* eslint-disable react/prop-types */
import { useState } from 'react';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FeedbackCreate({ resetPage }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { success },
      } = await API.post(`/report/crimeType`, {
        type_name: name,
      });
      if (success === 1) {
        resetPage();
        setName('');
        handleClose();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ minWidth: '650px' }}>
        <DialogTitle>Гэмт хэргийн төрөл нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            getOptionLabel={(option) => option.type_name}
            label="Гэмт хэргийн төрөл"
            type="text"
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
