/* eslint-disable react/prop-types */
import { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components

import API, { API_URL } from '../../../api';

export default function UserProfile() {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [position, setPosition] = useState('');
  const [email, setMail] = useState('');

  const [phoneNumber, setPhone] = useState('');

  const [biography, setBiography] = useState('');
  const [paths, setPaths] = useState([]);
  const [photo, setPhoto] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    headers: { Authorization: `Token ${accessToken}` },
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
    },
    [setPaths]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop,
  });

  const handleClickOpen = () => {
    fetchProfileData();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchProfileData = async () => {
    setLoading(true);
    const config = {
      headers: { Authorization: `Token ${accessToken}` },
    };
    const {
      data: { data },
    } = await API.get(`/auth/me/info`, config);
    console.log(data);
    const { admin_id, position, firstName, phoneNumber, email, image } = data;
    setFirstName(firstName);
    setPosition(position);
    setMail(email);
    setPhone(phoneNumber);
    setPaths([`${API_URL}${image}`]);
    setPhoto(image);

    setLoading(false);
  };

  const resetFields = () => {
    setFirstName('');
    setPosition('');
    setMail('');
    setPhone('');
    setBiography('');
    setPaths([]);
    window.location.reload();
    // forceUpdate()
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Token ${accessToken}` } };
    const personData = new FormData();

    personData.append('photo', photo);
    personData.append('icon', acceptedFiles[0]);
    personData.append('firstName', firstName);
    personData.append('position', position);
    personData.append('email', email);
    personData.append('phoneNumber', phoneNumber);

    try {
      setLoading(true);
      const response = await API.put(`/auth`, personData, config);
      const {
        data: { message, success },
      } = response;
      if (success === true) {
        resetFields();
        handleClose();
        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <>
      <Box variant="contained" onClick={handleClickOpen}>
        Өөрийн мэдээлэл засах
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Өөрийн мэдээлэл засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                label="Нэр"
                type="text"
                fullWidth
                autoFocus
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={position}
                onChange={(event) => setPosition(event.target.value)}
                label="Албан тушаал"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={email}
                onChange={(event) => setMail(event.target.value)}
                label="Цахим шуудан"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{
                  my: 1,
                }}
                value={phoneNumber}
                onChange={(event) => setPhone(event.target.value)}
                label="Утасны дугаар"
                type="text"
                fullWidth
              />
            </Grid>

            <Grid item xs={6}>
              {paths.length > 0 ? (
                paths.map((path, key) => (
                  <Box
                    key={path}
                    sx={{
                      border: '1px solid #ebebeb',
                      borderRadius: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <img src={path} alt={imageFiles[key] || 'no photo'} width="100" height="100" />
                    <Button onClick={() => setPaths([])}>Зураг солих</Button>
                  </Box>
                ))
              ) : (
                <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                  <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                    <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                  </div>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
