// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  // {
  //   title: 'Самбар',
  //   path: '/dashboard/app',
  //   icon: icon('ic_analytics'),
  // },
  {
    title: 'Мэдээ',
    path: '/dashboard/news',
    icon: icon('ic_blog'),
  },
  {
    title: 'Категори',
    path: '/dashboard/categories',
    icon: icon('ic_lock'),
  },
  {
    title: 'Баннер',
    path: '/dashboard/banner',
    icon: icon('ic_lock'),
  },
  {
    title: 'Бидний тухай',
    path: '/dashboard/about',
    icon: icon('ic_user'),
  },
  {
    title: 'Гэмт хэргүүд',
    path: '/dashboard/crime-reports',
    icon: icon('ic_disabled'),
  },
  {
    title: 'Удирдлагын баг ',
    path: '/dashboard/team',
    icon: icon('ic_user'),
  },
  {
    title: 'Үнэлгээ',
    path: '/dashboard/feedback',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Chatbot Асуулт хариулт',
    path: '/dashboard/faq',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Chatbot Түлхүүр үг',
    path: '/dashboard/keys',
    icon: icon('ic_lock'),
  },
  {
    title: 'Гэмт хэргийн бүлгүүд',
    path: '/dashboard/report-types',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Харьяалах газар',
    path: '/dashboard/department',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Зөрчлийн өнгө',
    path: '/dashboard/sub-crime-types',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Мэдээлсэн Гэмт хэргүүд',
    path: '/dashboard/crimes',
    icon: icon('ic_blog'),
  },
  {
    title: 'Гэмт хэргийн төрөл',
    path: '/dashboard/crime-types',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Харьяа Хэлтэс',
    path: '/dashboard/division',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Хэрэглэгч',
    path: '/dashboard/admin',
    icon: icon('ic_user'),
  },
  {
    title: 'Файлын сан',
    path: '/dashboard/files',
    icon: icon('ic_file'),
  },
];

export default navConfig;
