/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Card,
  Avatar,
  CircularProgress,
  TablePagination,
} from '@mui/material';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// components
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsModal({ resetPage, categories }) {
  const [open, setOpen] = useState(false);
  const [openImageSelect, setOpenImageSelect] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const [subCategories, setSubCategories] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();

  const [checked, setChecked] = useState(false);
  const [commendable, setCommendable] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [otherNews, setOtherNews] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [status, setStatus] = useState(false);

  const [publishDate, setPublishDate] = useState(dayjs());

  const [link, setLink] = useState('');

  const [paths, setPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
    },
    [setPaths]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop,
  });

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const removeAll = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    acceptedPDFFiles.length = 0;
    acceptedPDFFiles.splice(0, acceptedFiles.length);
  };
  const handleClose = () => {
    setTitle('');
    setContent('');
    setDescription('');
    setSubCategories('');
    setSelectedSubCategory('');
    setChecked(false);
    setLink('');
    removeAll();
    setOpen(false);
    setSelectedImage(null);
    setPaths([]);
    setCommendable(false);
    setIsNew(false);
    setOtherNews(false);
  };

  const showError = (field) => {
    toast.error(`${field}  оруулна уу!`, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const handleSubmit = async () => {
    // console.log(selectedImage);

    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();

    if (!title) {
      showError('Гарчиг');
      return;
    }
    if (!description) {
      showError('Тайлбар');
      return;
    }
    // if (!content) {
    //   showError('Мэдээний контент');
    //   return;
    // }
    if (!selectedSubCategory) {
      showError('Дэд категори');
      return;
    }

    // if (!checked && !selectedImage && !acceptedFiles[0]) {
    //   showError('Зураг');
    //   return;
    // }

    newsData.append('n_img', selectedImage ? selectedImage.file_path : acceptedFiles[0]);
    newsData.append('n_title', title);
    newsData.append('n_desc', description);
    newsData.append('n_content', content);
    newsData.append('n_video_link', link);
    if (selectedSubCategory) newsData.append('child_sub_cat_id', selectedSubCategory);
    else
      toast.error('Дэд категори байхгүй. Категори сонгоно уу', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    newsData.append('n_files', acceptedPDFFiles[0]);
    newsData.append('status', status ? 1 : 0);
    newsData.append('accept_comment', commendable ? 1 : 0);
    newsData.append('show_news', otherNews ? 1 : 0);
    newsData.append('is_new', isNew ? 1 : 0);
    newsData.append('show_img', showImage ? 1 : 0);
    newsData.append('posted_date', dayjs(new Date(publishDate)).format());

    try {
      setLoading(true);
      const response = await API.post(`/admin`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSelectCategory = (data) => {
    setSubCategories([]);
    const { sub_cat_id, sub_cat_name } = data;
    if (sub_cat_id && sub_cat_name) {
      const subIds = sub_cat_id.split(',');
      const subNames = sub_cat_name.split(',');
      const list = subIds.map((id, index) => ({ sub_cat_id: id, sub_cat_name: subNames[index] }));
      setSubCategories(list);
    } else {
      toast.error('Дэд категори байхгүй. Категори цэс рүү орж нэмнэ үү', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const onSelectSubCategory = (data) => {
    setSelectedSubCategory(data.sub_cat_id);
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, _progress) =>
    new Promise((resolve, _reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handlePickImage = () => {
    setOpenImageSelect(true);
  };

  const handleSelectImage = (data) => {
    // console.log(data);
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };

  return (
    <div>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Мэдээ нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label="Гарчиг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                label="Дэд гарчиг"
                type="text"
                fullWidth
              />
              <Autocomplete
                disablePortal
                id="category-box"
                isOptionEqualToValue={(option, value) => option.cat_name === value.cat_name}
                getOptionLabel={(option) => option.cat_name}
                options={categories || []}
                sx={{ width: 1, my: 1 }}
                onChange={(_event, value) => onSelectCategory(value)}
                renderInput={(params) => <TextField {...params} label="Категори сонгох" />}
              />
              {subCategories && subCategories.length > 0 ? (
                <Autocomplete
                  disablePortal
                  id="sub-category-box"
                  isOptionEqualToValue={(option, value) => option.sub_cat_name === value.sub_cat_name}
                  getOptionLabel={(option) => option.sub_cat_name}
                  options={subCategories}
                  sx={{ width: 1, mb: 2 }}
                  onChange={(_event, value) => onSelectSubCategory(value)}
                  renderInput={(params) => <TextField {...params} label="Дэд категори сонгох" />}
                />
              ) : (
                <Box ml={1} color="red">
                  Дэд категори байхгүй. Категори сонгох эсвэл цэс рүү орж нэмнэ үү
                </Box>
              )}
              <Editor
                className="z-[9999]"
                apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
                onInit={(_evt, editor) => {
                  setContent(editor.getContent());
                  // console.log(editor)

                  const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
                  editor.editorContainer.appendChild(tinyAuxEl);
                }}
                value={content}
                onEditorChange={(curContent, _editor) => {
                  setContent(curContent);
                }}
                init={{
                  height: 500,
                  menubar: false,
                  readonly: false,
                  plugins: `preview importcss searchreplace autolink autosave save 
                    directionality code visualblocks visualchars fullscreen 
                    image link media template codesample table charmap 
                    pagebreak nonbreaking anchor advlist 
                    lists wordcount help charmap emoticons table code`,
                  toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                    | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                    | outdent indent | numlist bullist 
                    | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                    |  save print table code
                    | insertfile image media template link anchor codesample | ltr rtl`,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  file_picker_types: 'file image media',
                  images_upload_handler: upImg,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Dialog open={openImageSelect} fullWidth maxWidth={false}>
                  <DialogTitle>Зургийн сан</DialogTitle>
                  <DialogContent
                    sx={{
                      py: 1,
                    }}
                  >
                    <ImageSelector handleSelectImage={handleSelectImage} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageSelect(false)}>Цуцлах</Button>
                  </DialogActions>
                </Dialog>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Зураг
                  </div>
                  <Button onClick={handlePickImage}>Зургийн сангаас сонгох</Button>
                  {paths.length > 0 ? (
                    paths.map((path, key) => (
                      <Box
                        key={path}
                        sx={{
                          border: '1px solid #ebebeb',
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <img src={path} alt={imageFiles[key] || 'no photo'} width="100" height="100" />
                        <Button onClick={() => setPaths([])}>Зураг солих</Button>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                    </Box>
                  )}
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    PDF Файл
                  </div>
                  <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <div {...getPDFRootProps({ className: 'dropzone' })}>
                      <input {...getPDFInputProps()} />
                      <p>PDF файлыг энд чирж, эсвэл энд товшино уу.</p>
                      <em>(Зөвхөн *.pdf файлыг хүлээн авна)</em>
                      <em>(Дахин дарж солино уу.)</em>
                    </div>
                    {PDFFiles.length > 0 && (
                      <aside>
                        <h4>PDF:</h4>
                        <p>{PDFFiles}</p>
                      </aside>
                    )}
                  </Box>
                </Box>
              </Card>
              <Card variant="outlined">
                <Box m={1}>
                  <div>Тохиргоо</div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={status}
                          onChange={(event) => {
                            setStatus(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={status ? 'Нийтлэгдсэн' : 'Хүлээгдэж буй'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(event) => {
                            setChecked(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="ВИДЕО МЭДЭЭ"
                    />
                  </FormGroup>
                  {checked && (
                    <TextField
                      sx={{
                        my: 1,
                      }}
                      value={link}
                      onChange={(event) => setLink(event.target.value)}
                      label="Линк"
                      type="text"
                      fullWidth
                      autoFocus
                    />
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={commendable}
                          onChange={(event) => {
                            setCommendable(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Сэтгэгдэл ${commendable ? 'авна' : 'авахгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isNew}
                          onChange={(event) => {
                            setIsNew(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Шинэ мэдээ: ${isNew ? 'Тийм' : 'Үгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={otherNews}
                          onChange={(event) => {
                            setOtherNews(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Бусад мэдээ: ${otherNews ? 'Харагдана' : 'Харагдахгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showImage}
                          onChange={(event) => {
                            setShowImage(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Мэдээний зураг: ${showImage ? 'Харагдана' : 'Харагдахгүй'}`}
                    />
                  </FormGroup>
                  <DateTimePicker
                    sx={{
                      my: 1,
                    }}
                    value={publishDate}
                    onChange={(newValue) => setPublishDate(newValue)}
                    label="Нийтлэгдэх огноо"
                    type="text"
                    ampm={false}
                    fullWidth
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ImageSelector = ({ handleSelectImage }) => {
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [images, setImages] = useState([]);

  const fetchImages = async (page = 1, limit = 20) => {
    const response = await API.get(`/upload/search?page=${page}&limit=${limit}&text=&file_type=image`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setImages(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchImages(page, rowsPerPage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const handleSelectImage = (data) => {
  //   console.log(data);
  //   setSelectedImage(data);
  // };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress />}

        <Grid container alignItems="center" justifyContent="center" m={5} p={5} gap={1}>
          {images && images.length > 0 ? (
            <>
              {images.map((imageData, key) => {
                const { file_path, file_id } = imageData;
                return (
                  <Grid
                    key={key}
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ebebeb',
                      ':hover': {
                        cursor: 'pointer',
                        opacity: 80,
                        bg: '#ebebeb',
                      },
                    }}
                    p={1}
                    onClick={() => handleSelectImage(imageData)}
                  >
                    <Avatar
                      sx={{ bgcolor: '#ebebeb', width: '100px', height: '100px' }}
                      alt="img"
                      src={`${API_URL}${file_path}`}
                      variant="square"
                    />
                    <Box sx={{ textAlign: 'center', fontSize: '12px' }}>{file_path.split('/').pop()}</Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
              No Images
            </Grid>
          )}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
};
