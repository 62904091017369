/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function DivNew({ resetAll }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [order, setOrder] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.post(`/division`, { d_name: name, order_n: order });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        setName('');
        setOrder('');
        handleClose();
        resetAll();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Харьяа Хэлтэс нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Нэр"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={order}
            onChange={(event) => setOrder(event.target.value)}
            label="Дараалал"
            type="text"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
