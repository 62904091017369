import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Popover,
  Avatar,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  IconButton,
  CircularProgress,
  Grid,
} from '@mui/material';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import AboutUpdate from '../sections/@dashboard/about/AboutUpdate';
// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

const ABOUT_ITEMS = [
  {
    id: 'greeting',
    name: 'Мэндчилгээ',
  },
  {
    id: 'structure',
    name: 'Бүтэц',
  },
  {
    id: 'mission',
    name: 'Эрхэм зорилго',
  },
];

export default function AboutPage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});

  const fetchContents = async () => {};

  useEffect(() => {
    try {
      setLoading(true);
      fetchContents();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedItem(data);
    setOpen(event.currentTarget);
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedItem({});
    fetchContents();
  };

  return (
    <>
      <Helmet>
        <title> Бидний тухай</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Бидний тухай
          </Typography>
        </Stack>

        <Scrollbar>
          <Card>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 400 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Хуудас</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ABOUT_ITEMS.map((rowData, index) => {
                    const { id, name } = rowData;
                    return (
                      <TableRow key={id} hover>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap px={2}>
                              {index + 1}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle" noWrap px={2}>
                              {name}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, rowData)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <AboutUpdate selectedItem={selectedItem} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
