/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function ReportUpdate({ resetPage, selectedPerson }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [desc, setDesc] = useState('');
  const [longitude, setLongitude] = useState('');
  const [latitude, setLatitude] = useState('');

  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();

  const [reportTypes, setReportTypes] = useState([]);
  const [selectedReportType, setSelectedReportType] = useState();

  const [checked, setChecked] = useState(false);

  const fetchDepartments = async () => {
    try {
      setLoading(true);
      const response = await API.get(`/department`);
      const {
        data: { data },
      } = response;
      setDepartments(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState();

  const fetchFeedbacks = async () => {
    const response = await API.get('/report/crimeType');
    const {
      data: { data },
    } = response;
    setTypes(data);
  };

  const fetchReportTypes = async (id) => {
    const response = await API.get(`/report/type/${id}`);
    const {
      data: { data },
    } = response;
    setReportTypes(data);
  };

  useEffect(() => {
    fetchDepartments();
    fetchFeedbacks();

    const { u_phone, u_name, r_desc, c_longitude, c_latitude, type_name, type_id, c_is_family_violence } =
      selectedPerson;
    setPhone(u_phone);
    setName(u_name);
    setType(type_name);
    setDesc(r_desc);
    setLongitude(c_longitude);
    setLatitude(c_latitude);
    setChecked(c_is_family_violence === 'tiim');

    fetchReportTypes(type_id);
  }, [selectedPerson]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.post(`/report/approve`, {
        ...selectedPerson,
        dep_name: selectedDepartment?.title || '',
        c_type: selectedType?.title || '',
        c_sub_type_name: selectedReportType?.title || '',
        c_date: selectedPerson.r_date,
        c_is_family_violence: checked ? 'tiim' : 'ugui',
      });
      const {
        data: { success },
      } = response;
      if (success === 1) {
        resetPage();
        handleClose();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Дэлгэрэнгүй
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Баталгаажуулах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={phone}
            disabled
            label="Утас"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            disabled
            label="Нэр"
            type="text"
            fullWidth
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={desc}
            disabled
            label="Мэдээлэл"
            type="text"
            fullWidth
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={type}
            disabled
            label="Төрөл"
            type="text"
            fullWidth
          />
          <Autocomplete
            disablePortal
            id="category-box"
            options={reportTypes.map((data) => ({
              title: data.type_name,
            }))}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            sx={{ width: 1, mb: 2 }}
            onChange={(_, value) => setSelectedReportType(value)}
            renderInput={(params) => <TextField {...params} label="Гэмт хэрэг төрөл" />}
            noOptionsText={'Санал болгох зөрчлийн өнгө олдсонгүй. Зөрчлийн өнгийг нэмнэ үү.'}
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={longitude}
            disabled
            label="longitude"
            type="text"
            fullWidth
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={latitude}
            disabled
            label="latitude"
            type="text"
            fullWidth
          />
          <Autocomplete
            disablePortal
            id="category-box"
            options={departments.map((data) => ({
              title: data.dep_name,
            }))}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            sx={{ width: 1, mb: 2 }}
            onChange={(_, value) => setSelectedDepartment(value)}
            renderInput={(params) => <TextField {...params} label="Хэлтэс сонгох" />}
          />
          <Autocomplete
            disablePortal
            id="category-box"
            options={types.map((data) => ({
              title: data.type_name,
            }))}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            sx={{ width: 1, mb: 2 }}
            onChange={(_, value) => setSelectedType(value)}
            renderInput={(params) => <TextField {...params} label="Гэмт хэрэг сонгох" />}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <>
                  Гэр бүлийн хүчирхийлэл эсэх:
                  <b style={{ margin: '0 1rem' }}>{checked ? 'Тийм' : 'Үгүй'}</b>
                </>
              }
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Буцах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Баталгаажуулах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
