/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import { Helmet } from 'react-helmet-async';
import update from 'immutability-helper';
import { useEffect, useState, useRef, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Grid,
  Popover,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  IconButton,
} from '@mui/material';

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import {
  CategoryUpdate,
  SubCategoryUpdate,
  CategoryDelete,
  CategoryNew,
  SubCategoryNew,
  SubCategoryDelete,
} from '../sections/@dashboard/category';
// api
import API from '../api';

// ----------------------------------------------------------------------

const DragableCard = ({ id, content, index, moveCard, fetchSubCategoryById, rowData }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <TableRow
      hover
      onClick={() => fetchSubCategoryById && fetchSubCategoryById(rowData)}
      ref={ref}
      data-handler-id={handlerId}
      sx={{
        opacity,
      }}
    >
      {content}
    </TableRow>
  );
};

const SubCardContainer = ({ categories, fetchSubCategoryById, handleOpenMenu }) => {
  const [cards, setCards] = useState(categories);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const sortSubCategories = useCallback(async () => {
    const payload = cards.map((cardData, index) => {
      return {
        sub_cat_id: cardData.sub_cat_id,
        order_n: index,
      };
    });

    await API.put('/category/subCategory/list', payload);
  }, [cards]);

  useEffect(() => {
    sortSubCategories();
  }, [cards]);

  const renderCard = useCallback(
    (rowData, index) => {
      const { sub_cat_id, sub_cat_name } = rowData;
      return (
        <DragableCard
          key={sub_cat_id}
          index={index}
          id={sub_cat_id}
          rowData={rowData}
          content={
            <>
              <TableCell
                align="left"
                sx={{
                  maxWidth: 50,
                  px: 1,
                }}
              >
                <IconButton size="large" color="inherit">
                  <Iconify icon={'mdi:drag'} cursor="grab" />
                </IconButton>
              </TableCell>
              <TableCell align="left" component="th" scope="row" padding="none">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="subtitle2" noWrap px={2}>
                    {sub_cat_name}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, rowData)}>
                  <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </TableCell>
            </>
          }
          moveCard={moveCard}
        />
      );
    },
    [moveCard, fetchSubCategoryById, handleOpenMenu]
  );
  return <>{cards.map((card, i) => renderCard(card, i))}</>;
};

const CardContainer = ({ categories, fetchSubCategoryById, handleOpenMenu }) => {
  const [cards, setCards] = useState(categories);
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCards((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const sortCategories = useCallback(async () => {
    const payload = cards.map((cardData, index) => {
      return {
        cat_id: cardData.cat_id,
        order_n: index,
      };
    });

    await API.put('/category/list', payload);
  }, [cards]);

  useEffect(() => {
    sortCategories();
  }, [cards]);

  const renderCard = useCallback(
    (rowData, index) => {
      const { cat_id, cat_name } = rowData;
      return (
        <DragableCard
          key={cat_id}
          index={index}
          id={cat_id}
          rowData={rowData}
          fetchSubCategoryById={fetchSubCategoryById}
          content={
            <>
              <TableCell
                align="left"
                sx={{
                  maxWidth: 50,
                  px: 1,
                }}
              >
                <IconButton size="large" color="inherit">
                  <Iconify icon={'mdi:drag'} cursor="grab" />
                </IconButton>
              </TableCell>
              <TableCell align="left" component="th" scope="row" padding="none">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Typography variant="subtitle2" noWrap px={2}>
                    {cat_name}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">
                <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, rowData)}>
                  <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              </TableCell>
            </>
          }
          moveCard={moveCard}
        />
      );
    },
    [moveCard, fetchSubCategoryById, handleOpenMenu]
  );
  return <>{cards.map((card, i) => renderCard(card, i))}</>;
};

export default function CategoryPage() {
  const [open, setOpen] = useState(null);

  const [openSub, setOpenSub] = useState(null);

  const [loading, setLoading] = useState(false);
  const [subLoading, setSubLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState();

  const [selectedSubCategory, setSubSelectedCategory] = useState();

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const fetchCategories = async (page, limit) => {
    const response = await API.get('/category');
    const {
      data: { data, success },
      status,
    } = response;
    if (success === 1) setCategories(data);
  };
  const fetchSubCategories = async (id) => {
    const response = await API.get(`/category/${id}`);
    const {
      data: { data },
    } = response;
    setSubCategories(data);
  };

  const handleSubAdd = async (name) => {
    try {
      setLoading(true);
      const response = await API.post(`/category/subCategory`, {
        cat_id: selectedCategory.cat_id,
        sub_cat_name: name,
      });
      const {
        data: { data },
        success,
      } = response;
      if (success === 1) {
        fetchCategories();
        fetchSubCategories(selectedCategory.cat_id);
        handleCloseMenu();
      }
    } catch (error) {
      console.warn(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchCategories();
    } catch (error) {
      console.warn(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenSub(null);
  };

  const handleCategoryDelete = () => {};

  const handleSubCategoryDelete = () => {};

  const handleOpenMenu = (event, data) => {
    setSelectedCategory(data);
    setOpen(event.currentTarget);
  };

  const handleOpenSubMenu = (event, data) => {
    setSubSelectedCategory(data);
    setOpenSub(event.currentTarget);
  };

  const fetchSubCategoryById = (data) => {
    try {
      setSubLoading(true);
      setSelectedCategory(data);
      setSubCategories([]);
      fetchSubCategories(data.cat_id);
    } catch (error) {
      console.error(error.message);
    } finally {
      setSubLoading(false);
    }
  };

  const resetAll = () => {
    handleCloseMenu();
    setCategories([]);
    fetchCategories();
    if (selectedCategory.cat_id) {
      setSubCategories([]);
      fetchSubCategories(selectedCategory.cat_id);
    }
  };

  return (
    <>
      <Helmet>
        <title> Категори </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Категори
          </Typography>
        </Stack>

        <Scrollbar>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Категори</TableCell>
                        <TableCell />
                        <TableCell align="right">
                          <CategoryNew resetAll={resetAll} />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <DndProvider backend={HTML5Backend}>
                      <TableBody>
                        {categories.length > 0 ? (
                          <CardContainer
                            categories={categories}
                            handleOpenMenu={handleOpenMenu}
                            fetchSubCategoryById={fetchSubCategoryById}
                          />
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Өгөгдөл олдсонгүй
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </DndProvider>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card>
                <TableContainer sx={{ minWidth: 400 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell> Дэд категори</TableCell>
                        <TableCell />
                        <TableCell align="right">
                          <SubCategoryNew
                            handleSubAdd={handleSubAdd}
                            resetAll={resetAll}
                            id={selectedCategory?.cat_id}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <DndProvider backend={HTML5Backend}>
                      <TableBody>
                        {subCategories.length > 0 ? (
                          <SubCardContainer
                            key={selectedCategory.cat_id}
                            categories={subCategories}
                            handleOpenMenu={handleOpenSubMenu}
                          />
                        ) : (
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Өгөгдөл олдсонгүй
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </DndProvider>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
          </Grid>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <CategoryUpdate category={selectedCategory} resetAll={resetAll} />
        </MenuItem>

        <MenuItem onClick={handleCategoryDelete} sx={{ color: 'error.main' }}>
          <CategoryDelete id={selectedCategory?.cat_id} resetPage={resetAll} />
        </MenuItem>
      </Popover>

      <Popover
        open={Boolean(openSub)}
        anchorEl={openSub}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <SubCategoryUpdate
            subCategory={selectedSubCategory}
            parentCatId={selectedCategory?.cat_id}
            resetAll={resetAll}
          />
        </MenuItem>

        <MenuItem onClick={handleSubCategoryDelete} sx={{ color: 'error.main' }}>
          <SubCategoryDelete id={selectedSubCategory?.sub_cat_id} resetPage={resetAll} />
        </MenuItem>
      </Popover>
    </>
  );
}
