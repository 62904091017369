/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete, Box } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FeedbackUpdate({ resetPage, selectedFeedback }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [subTypeName, setSubTypeName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [typeName, setTypeName] = useState('');

  const [reportTypes, setReportTypes] = useState([]);

  const [selectedReportType, setSelectedReportType] = useState();

  const fetchCategories = async () => {
    try {
      const response = await API.get(`/report/type`);
      const {
        data: { data },
      } = response;
      setReportTypes(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    const { type_name, c_sub_type_name } = selectedFeedback;
    setSubTypeName(c_sub_type_name);
    setTypeName(type_name);

    fetchCategories();
  }, [selectedFeedback]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { message, success },
      } = await API.put(`/crime/type`, {
        c_sub_type_id: selectedFeedback.c_sub_type_id,
        c_sub_type_name: subTypeName,
        type_id: selectedReportType.id,
      });
      if (success === 1) {
        resetPage();
        setSubTypeName('');
        setTypeName('');
        handleClose();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>Зөрчлийн өнгө засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
            minHeight: '50vh',
          }}
        >
          <TextField
            sx={{
              my: 2,
              minWidth: '450px',
            }}
            value={subTypeName}
            onChange={(event) => setSubTypeName(event.target.value)}
            label="Зөрчлийн өнгө"
            type="text"
            fullWidth
            autoFocus
          />
          <Box>
            <Autocomplete
              disablePortal
              id="category-box"
              options={reportTypes.map((data) => ({
                id: data.type_id,
                title: data.type_name,
              }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.title}
              onChange={(_, value) => {
                setSelectedReportType(value);
              }}
              renderInput={(params) => <TextField {...params} label="Гэмт хэрэг төрөл" />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
