/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../components/iconify';

import API from '../api';

export default function CrimeDelete({ resetAll }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.delete(`/crime/deleteLast`);
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetAll();
        handleClose();
        toast.success('Амжилттай устгагдлаа', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        sx={{ mt: 1, color: 'error.main' }}
        variant="outlined"
        color="error"
        startIcon={<Iconify icon={'eva:trash-2-outline'} />}
        onClick={handleClickOpen}
      >
        Устгах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Хүн устгах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <h4>Хамгийн сүүлд импорт хийсэн мэдээллүүдийг устгах болно.</h4>
          Устгахдаа итгэлтэй байна уу?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? (
            'Уншиж байна...'
          ) : (
            <Button onClick={handleSubmit} color="error">
              Устгах
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
