/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';
import API from '../../../api';

export default function SubCategoryNew({ resetAll, id }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cat_id, setId] = useState();

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [link, setLink] = useState('');

  const [checked, setChecked] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setId(id);
  }, [id]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.post(`/category/subCategory`, {
        cat_id,
        sub_cat_name: name,
        sub_cat_name_en: nameEn,
        sub_cat_link: link,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        setName('');
        handleClose();
        resetAll();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Дэд категори нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Нэр"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={nameEn}
            onChange={(event) => setNameEn(event.target.value)}
            label="Англи"
            type="text"
            fullWidth
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Линк оруулах"
            />
          </FormGroup>
          {checked && (
            <TextField
              sx={{
                my: 2,
              }}
              value={link}
              onChange={(event) => setLink(event.target.value)}
              label="Линк"
              type="text"
              fullWidth
              autoFocus
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={() => handleSubmit()}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
