/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { differenceInDays, format } from 'date-fns';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';

import { toast } from 'react-toastify';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { ArchiveListHead, ArchiveUpdate } from '../sections/@dashboard/feedbackArchive';
// api
import API from '../api';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'index', label: '№', alignRight: false },
  { id: 'feedbackNumber', label: 'Хүсэлтийн дугаар', alignRight: false },
  { id: 'firstname', label: 'Хүсэлт гаргагчийн нэр', alignRight: false },
  { id: 'feedbackText', label: 'Дэлгэрэнгүй утга', alignRight: false },
  // { id: 'description', label: 'Дэд гарчиг', alignRight: false },
  { id: 'createdAt', label: 'Илгээсэн огноо', alignRight: false },
  { id: 'deadline', label: 'Шийдвэрлэх сүүлийн огноо', alignRight: false },
  { id: 'status', label: 'Төлөв', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function FeedbackApprovedPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [request, setRequest] = useState([]);

  const [selectedRequest, setSelectedRequest] = useState({});

  const [totalRequestCount, setTotalRequestCount] = useState(0);

  const [catID, setCatID] = useState(null);
  const [subCatID, setSubCatID] = useState(null);

  const [payload, setPayload] = useState(null);
  const accessToken = localStorage.getItem('accessToken');
  const config = {
    headers: { Authorization: `Bearer ${accessToken}` },
  };
  const fetchRequest = async ({ page = 1, limit = 20, payload }) => {
    const response = await API.get(
      `/govService/feedback/show?page=${page}&limit=${limit}&status=Зөвшөөрөгдсөн&archive=true`,
      config,
      {
        startDate: '1900-01-01',
        endDate: '2999-01-01',
        n_title: '',
        n_id: '',
        cat_id: '',
        sub_cat_id: '',
        a_username: '',
      }
    );
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    if (response.data.success === true) {
      setRequest(data);
      setTotalRequestCount(total);
    }
  };

  const filterSearch = async (payload) => {
    setPayload(payload);
    const {
      title,
      newsId,
      selectedUser,
      startDate,
      endDate,
      selectedCategory,
      selectedSubCategory,
      page = 1,
      limit = rowsPerPage,
    } = payload;
    const formattedStartDate = dayjs(new Date(startDate)).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(new Date(endDate)).format('YYYY-MM-DD');

    const response = await API.post(`/admin/filter?page=${page}&limit=${limit}`, {
      n_title: title,
      n_id: newsId,
      cat_id: selectedCategory?.cat_id || '',
      sub_cat_id: selectedSubCategory?.sub_cat_id || '',
      a_username: selectedUser?.a_username || '',
      startDate: startDate ? formattedStartDate : '1900-01-01',
      endDate: formattedEndDate,
    });
    const {
      data: {
        data,
        success,
        pagination: { total },
      },
    } = response;
    if (success === 1) {
      setRequest(data);
      setTotalRequestCount(total);
    }
  };

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const response = await API.get('/category');
    const {
      data: { data, success },
    } = response;
    if (success === 1) setCategories(data);
  };

  const resetPage = () => {
    setPage(0);
    setPayload(null);
    setSelectedRequest({});
    setOpen(false);
    try {
      setLoading(true);
      fetchRequest({ page, limit: rowsPerPage });
      fetchCategories();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (payload) {
        fetchRequest({ payload, page: page + 1, limit: rowsPerPage });
        filterSearch({ ...payload, page: page + 1, limit: rowsPerPage });
      } else {
        fetchRequest({ payload, page: page + 1, limit: rowsPerPage });
      }
      fetchCategories();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDelete = async () => {
    // todo: call api
    try {
      const response = await API.post(`/govService/feedback/${selectedRequest._id}`);
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        handleCloseMenu();
        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        fetchRequest(page, rowsPerPage);
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOpenMenu = (event, selectedRequest) => {
    setOpen(event.currentTarget);
    console.log(selectedRequest);
    setSelectedRequest(selectedRequest);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onSelectCategory = (category) => {
    setPage(0);
    // setSelectedCategory(category);
  };

  return (
    <>
      <Helmet>
        <title>Зөвшөөрсөн Санал Хүсэлт </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom color={'white'}>
            Зөвшөөрсөн Санал Хүсэлт
          </Typography>
        </Stack>

        <Card>
          {/* <RequestListToolbar
            resetPage={resetPage}
            onSelectCategory={onSelectCategory}
            categories={categories}
            filterSearch={filterSearch}
          /> */}
          {loading ? (
            <CircularProgress />
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <ArchiveListHead headLabel={TABLE_HEAD} rowCount={request.length} />
                  <TableBody>
                    {request.length > 0 ? (
                      request.map((row, index) => {
                        const {
                          _id,
                          firstname,
                          feedbackNumber,
                          registerNumber,
                          createdAt,
                          status,
                          phoneNumber,
                          feedbackText,
                          address,
                          deadline,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {feedbackNumber}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {firstname || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {n_content ? (
                                    <>
                                      {n_content && n_content?.slice(0, 15)} {n_content?.length > 15 && '...'}
                                    </>
                                  ) : (
                                    '---'
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell> */}

                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {feedbackText || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>

                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2">{n_desc || '...'}</Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {createdAt ? format(new Date(createdAt), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    color: 'white',
                                    backgroundColor:
                                      deadline &&
                                      (differenceInDays(new Date(deadline), new Date()) >= 20 &&
                                      differenceInDays(new Date(deadline), new Date()) <= 30
                                        ? 'green'
                                        : differenceInDays(new Date(deadline), new Date()) <= 20 &&
                                          differenceInDays(new Date(deadline), new Date()) >= 10
                                        ? 'yellow'
                                        : 'red'),
                                    borderRadius: '8px',
                                    padding: '4px 8px',
                                  }}
                                >
                                  {deadline ? format(new Date(deadline), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {updated_at ? format(new Date(updated_at), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography
                                  variant="subtitle2"
                                  noWrap
                                  style={{
                                    color: 'inherit',
                                    backgroundColor:
                                      status === 'Зөвшөөрөгдсөн'
                                        ? 'green'
                                        : status === 'Цуцлагдсан'
                                        ? 'red'
                                        : status === 'Хүлээгдэж байгаа'
                                        ? 'yellow'
                                        : 'inherit', // Change to your desired background color
                                    borderRadius: '8px', // Adjust the radius as needed
                                    padding: '4px 8px', // Adjust padding as needed // Default color if none of the conditions match
                                  }}
                                >
                                  {status}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Өгөгдөл олдсонгүй
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalRequestCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <ArchiveUpdate request={selectedRequest} resetPage={resetPage} />
        </MenuItem>
        {/* <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Устгах
        </MenuItem> */}
      </Popover>
    </>
  );
}
