/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

// @mui
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FileUpdate({ resetPage, file }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');

  useEffect(() => {
    // const { n_content, n_desc, n_title, sub_cat_id } = category;

    if (file) {
      setName(file?.file_name || '');
      setFolderId(file?.folder_id);
    }

    fetchFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const [folders, setFolders] = useState([]);

  const fetchFolders = async () => {
    const response = await API.get('/upload/search?file_type=folder&text=');
    const { data } = response;
    const { success, message, data: listData } = data;
    if (success === 1) {
      setFolders(listData);
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [folderId, setFolderId] = useState(null);

  const handleChange = (event) => {
    setFolderId(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.put(`/upload`, {
        file_id: file.file_id,
        file_name: name,
        folder_id: folderId || file.folder_id,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetPage();
        handleClose();
        toast.success('Амжилттай шинэчлэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Файлыг засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Гарчиг"
            type="text"
            fullWidth
            autoFocus
          />
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Folder</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={folderId}
              label="Folder"
              onChange={handleChange}
            >
              {folders &&
                folders.length > 0 &&
                folders?.map(({ file_id, file_name }, index) => (
                  <MenuItem key={index} value={file_id}>
                    {file_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
