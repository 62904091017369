/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  FormGroup,
  FormControlLabel,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function SubCategoryUpdate({ subCategory, parentCatId, resetAll }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [link, setLink] = useState('');

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setName(subCategory.sub_cat_name);
    setNameEn(subCategory.sub_cat_name_en);
    setLink(subCategory.sub_cat_link);
    setChecked(!!subCategory.sub_cat_link);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategory]);

  const handleClickOpen = () => {
    setName(subCategory.sub_cat_name);
    setNameEn(subCategory.sub_cat_name_en);
    setLink(subCategory.sub_cat_link);
    setChecked(!!subCategory.sub_cat_link);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.put(`/category/subCategory`, {
        ...subCategory,
        cat_id: parentCatId,
        sub_cat_name: name,
        sub_cat_name_en: nameEn,
        sub_cat_link: link,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetAll();
        handleClose();
        toast.success('Амжилттай шинэчлэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Дэд категори засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Гарчиг"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={nameEn}
            onChange={(event) => setNameEn(event.target.value)}
            label="Англи"
            type="text"
            fullWidth
          />
          {link ? (
            <></>
          ) : (
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={(event) => {
                      setChecked(event.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Линк оруулах"
              />
            </FormGroup>
          )}
          {checked && (
            <TextField
              sx={{
                my: 2,
              }}
              value={link}
              onChange={(event) => setLink(event.target.value)}
              label="Линк"
              type="text"
              fullWidth
              autoFocus
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
