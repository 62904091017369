/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import {
  Chip,
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { CrimeListHead, CrimeListToolbar } from '../sections/@dashboard/crime';
import API from '../api';
import CrimeNew from './CrimeNew';
import CrimeDelete from './CrimeDelete';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'description', label: 'Гэр бүл хүчирхийлэл', alignRight: false },
  { id: 'type', label: 'Төрөл', alignRight: false },
  { id: '1', label: 'Төрөл / Зөрчлийн өнгө', alignRight: false },
  { id: '2', label: 'Хэлтэс', alignRight: false },
  { id: '3', label: 'Огноо', alignRight: false },
  { id: '4', label: 'Импорт', alignRight: false },
];

// ----------------------------------------------------------------------

export default function CrimePage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  // const [loading, setLoading] = useState(false)

  const [total, setTotal] = useState(0);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [crimeReports, setCrimeReports] = useState([]);

  const [selectedCrime, setSelectedCrime] = useState({});

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await API.get(`/report/type`);
      const {
        data: { data },
      } = response;
      setCategories(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchCrime = async (page = 1, limit = 25) => {
    try {
      const response = await API.get(`/crime?page=${page}&limit=${limit}`);
      const {
        data: {
          data,
          pagination: { total },
        },
      } = response;
      setTotal(total);
      setCrimeReports(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
    fetchCrime(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // const handleOpenMenu = (event, selectedCrime) => {
  //   setOpen(event.currentTarget);
  //   setSelectedCrime(selectedCrime);
  // };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = async ({ isParent, name, page = 1, limit = 25 }) => {
    const fetchPath = isParent
      ? `/crime/filter/type?page=${page}&limit=${limit}`
      : `/crime/filter/sub?page=${page}&limit=${limit}`;
    const bodyData = isParent
      ? {
          type: name,
        }
      : {
          sub_type: name,
        };
    const response = await API.post(fetchPath, bodyData);
    const {
      data: {
        data,
        success,
        pagination: { total },
      },
    } = response;
    if (success === 1) {
      setTotal(total);
      setCrimeReports(data);
    }
  };

  return (
    <>
      <Helmet>
        <title> Гэмт хэргүүд </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Гэмт хэргүүд
          </Typography>
          <div>
            <CrimeNew resetAll={fetchCrime} />
            <CrimeDelete resetAll={fetchCrime} />
          </div>
        </Stack>

        <Card>
          {/* todo: filter by category */}
          <CrimeListToolbar categories={categories} filterSearch={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <CrimeListHead headLabel={TABLE_HEAD} rowCount={crimeReports.length} />
                <TableBody>
                  {crimeReports.length > 0 ? (
                    crimeReports.map((row, index) => {
                      const { c_id, c_is_family_violence, c_date, c_type, c_sub_type_name, dep_name, is_imported } =
                        row;

                      return (
                        <TableRow hover key={`crime-${c_id}-${index}`}>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap px={2}>
                                {c_is_family_violence}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {c_type}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {c_sub_type_name?.slice(0, 15) || '---'}...
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {dep_name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {c_date}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">
                            <Chip label={is_imported === 'true' ? 'Тийм' : 'Үгүй'} />
                          </TableCell>
                          {/* <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Өгөгдөл олдсонгүй
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {/* 
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleApprove}>
          <Iconify icon={'eva:checkmark-fill'} sx={{ mr: 2 }} />
          Баталгаажуулах
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Устгах
        </MenuItem>
      </Popover> */}
    </>
  );
}
