import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar, Autocomplete, TextField } from '@mui/material';
// ----------------------------------------------------------------------

import API from '../../../api';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

CrimeListToolbar.propTypes = {
  filterSearch: PropTypes.func,
  categories: PropTypes.any,
};

export default function CrimeListToolbar({ categories, filterSearch }) {
  const [subCategories, setSubCategories] = useState([]);

  const fetchsub = async (id) => {
    try {
      const response = await API.get(`/report/type/${id}`);
      const {
        data: { data },
      } = response;
      setSubCategories(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const onSelectCategory = (data) => {
    fetchsub(data.type_id);
    filterSearch({
      isParent: true,
      name: data.type_name,
    });
  };

  const onSelectSubCategory = (data) => {
    filterSearch({
      isParent: false,
      name: data.c_sub_type_name,
    });
  };
  return (
    <StyledRoot>
      <Autocomplete
        disablePortal
        id="category-filter-box"
        isOptionEqualToValue={(option, value) => option.type_name === value.type_name}
        getOptionLabel={(option) => option.type_name}
        options={categories || []}
        sx={{ width: 300 }}
        onChange={(_event, value) => onSelectCategory(value)}
        renderInput={(params) => <TextField {...params} label="Категори шүүх" />}
      />
      {subCategories?.length > 0 && (
        <Autocomplete
          disablePortal
          id="sub-category-box"
          isOptionEqualToValue={(option, value) => option.c_sub_type_name === value.c_sub_type_name}
          getOptionLabel={(option) => option.c_sub_type_name}
          options={subCategories}
          sx={{ width: 300 }}
          onChange={(_event, value) => onSelectSubCategory(value)}
          renderInput={(params) => <TextField {...params} label="Дэд категори сонгох" />}
        />
      )}
    </StyledRoot>
  );
}
