/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Grid,
  Card,
  Typography,
  CardContent,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardMedia,
} from '@mui/material';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from 'date-fns';

// components
import { ImageSelector } from './RequestModal';
import Iconify from '../../../components/iconify';

import message from '../../../utils/sendMessage';

import API, { API_URL } from '../../../api';

export default function RequestUpdate({ request, resetPage }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [progress, setProgress] = useState(100);
  const [statuses, setStatuses] = useState(['Хүлээгдэж байгаа', 'Зөвшөөрөгдсөн', 'Цуцлагдсан']);
  const [messageValue, setMessageValue] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [remainingDay, setRemainingDay] = useState();
  const [paths, setPaths] = useState([]);
  const [colorClass, setColorClass] = useState();
  const [cadastral, setCadastral] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageSelect, setOpenImageSelect] = useState(false);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '*', // You can accept any image type here
    multiple: true, // Allow multiple files to be selected
    maxFiles: 10, // Set a maximum number of files (if needed)
  });

  useEffect(() => {
    if (cadastral === null) setCadastral(null);
    else setCadastral(request.cadastral);
    console.log(cadastral);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const createdAt = new Date(request.createdAt); // Replace with your createdAt value
    const deadline = new Date(request.deadline); // Replace with your deadline value
    const currentDate = new Date();
    const totalDuration = deadline - createdAt;
    const passedDuration = currentDate - createdAt;
    const progress = (passedDuration / totalDuration) * 100;
    const remainingDays = Math.floor((deadline - currentDate) / (1000 * 60 * 60 * 24));
    setRemainingDay(remainingDays);
    setProgress(progress);
    if (passedDuration <= 10 * 24 * 60 * 60 * 1000) {
      setColorClass('green');
    } else if (passedDuration <= 20 * 24 * 60 * 60 * 1000) {
      setColorClass('yellow');
    } else {
      setColorClass('red');
    }
  }, [request]);

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });
  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  const handleHeaderImageDelete = async (image) => {
    console.log(cadastral);
    try {
      setLoading(true);
      const data = { imageUrl: image, type: '1' };
      const response = await API.delete(`/project`, {
        data,
      });
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    await message(request.phoneNumber, request.requestNumber, request.status, messageValue).then(
      async (messageResponse) => {
        if (messageResponse.data.status === 200) {
          toast.success('Амжилттай', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          const feedbackData = new FormData();
          feedbackData.append('status', selectedStatus);
          try {
            setLoading(true);
            const response = await API.post(`/feedback/${request._id}`, feedbackData, config);
            const {
              data: { success, message },
            } = response;
            if (success === true) {
              resetPage();
              handleClose();
              toast.success(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            } else {
              toast.error(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            }
          } catch (error) {
            console.error(error.message);
          } finally {
            setLoading(false);
          }
        } else {
          toast.error(messageResponse.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
    );
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSelectImage = (data) => {
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };
  const handlePickImage = () => {
    setOpenImageSelect(true);
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
    },
    // Additional styling for the custom conditions
    '&.green': {
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: 'green',
      },
    },
    '&.yellow': {
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: 'yellow',
      },
    },
    '&.red': {
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: 'red',
      },
    },
  }));
  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Хүсэлт хариулах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Card>
            <CardContent>
              <Typography variant="h5" component="div">
                Хүсэлтийн дугаар-{request.requestNumber || '---'}
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <Grid sx={{ display: 'flex', gap: 13 }}>
                  <Grid item xs={8}>
                    <Typography variant="body1" color="textSecondary">
                      Хүсэлт гаргагч :
                    </Typography>
                    <Typography variant="h4" color="textSecondary">
                      {request.regNum && request.regNum !== null ? request.regNum : '---'}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="body1" color="textSecondary">
                      Илгээсэн хугацаа:
                    </Typography>
                    <Typography variant="h4" color="textSecondary">
                      {request.createdAt ? format(new Date(request.createdAt), 'yyyy-MM-dd') : '---'}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Grid item>
                      <Typography variant="body1" color="textSecondary" sx={{ width: 250 }}>
                        Шийдвэрлэх хугацаа:
                      </Typography>
                      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 1 }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={progress}
                          className={colorClass}
                          style={{ flex: 1 }}
                        />
                        <Typography variant="body2" color="textSecondary">
                          {remainingDay}-хоног үлдсэн
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Typography variant="body1" color="textSecondary">
                      Төлөв:
                    </Typography>
                    <Typography variant="h4" color="textSecondary">
                      {request.createdAt ? request.status : '---'}
                    </Typography>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '8px',
                    paddingTop: 8,
                    paddingBottom: 8,
                  }}
                >
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 5,
                      backgroundColor: '#27AE60',
                    }}
                  >
                    Зөвшөөрөх
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: '8px', borderRadius: 5, backgroundColor: '#EA5540' }}
                  >
                    Татгалзах
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>

          <Grid container spacing={4} paddingTop={1}>
            <Grid item xs={8}>
              <Card>
                <CardContent>
                  <Typography variant="h4">Илгээсэн маягт</Typography>
                  {request.formFile === null ? (
                    <Typography variant="body1">Маягт хоосон байна</Typography>
                  ) : (
                    <CardMedia
                      component="iframe"
                      src={`${API_URL}${request.formFile}`} // Replace with your iframe URL
                      title="Embedded Video"
                      height="600" // Set the height of the iframe
                      style={{ border: 'none' }}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              <Card>
                <Typography sx={{ paddingLeft: 2, paddingTop: 2 }} variant="h4">
                  Илгээсэн тодорхойлолтууд
                </Typography>
                {request.xypFiles.length > 0 &&
                  request.xypFiles.map((option, index) => (
                    <Accordion key={index}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">Тодорхойлолт - {index + 1}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <CardMedia
                          component="iframe"
                          src={`${API_URL}${option}`} // Replace with your iframe URL
                          title="todorhoilolt"
                          height="600" // Set the height of the iframe
                          style={{ border: 'none' }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Card>
              <Card>
                <CardContent>
                  <Typography variant="h5">Кадастрын зураг</Typography>
                  {cadastral === null ? (
                    <Box
                      sx={{
                        borderRadius: 1,
                        border: 'dotted',
                        borderColor: 'grey.500',
                        marginTop: 2,
                        p: 2,
                        textAlign: 'center',
                      }}
                    >
                      <div {...getPDFRootProps({ className: 'dropzone' })}>
                        <input {...getPDFInputProps()} />
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                      {PDFFiles.length > 0 && (
                        <aside>
                          <h4>Кадастрын зураг:</h4>
                          <p>{PDFFiles}</p>
                        </aside>
                      )}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #ebebeb',
                        borderRadius: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <img
                        src={`${API_URL}${cadastral}`}
                        alt={request.cadastral || 'no photo'}
                        width="full"
                        height="full"
                      />
                      <Button sx={{ color: 'error.main' }} onClick={() => handleHeaderImageDelete(cadastral)}>
                        Зураг устгах
                      </Button>
                    </Box>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="h4">Хүсэлт гаргагчид илгээх мессеж</Typography>
                  <TextField
                    sx={{
                      mb: 1,
                      marginTop: 2,
                    }}
                    multiline
                    rows={4}
                    value={messageValue}
                    onChange={(event) => setMessageValue(event.target.value)}
                    label="Хүсэлт гаргагчид илгээх мессеж утга"
                    type="text"
                    fullWidth
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
