import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import SimpleLayout from './layouts/simple';
import DashboardLayout from './layouts/dashboard';
//
import Page404 from './pages/Page404';
import NewsPage from './pages/NewsPage';
import TeamPage from './pages/TeamPage';
import CrimePage from './pages/CrimePage';
import LoginPage from './pages/LoginPage';
import ResetPage from './pages/ResetPage';
import CategoryPage from './pages/CategoryPage';
import ReportTypePage from './pages/ReportTypePage';
import QuestionKeyPage from './pages/QuestionKeyPage';
import DashboardAppPage from './pages/DashboardAppPage';
import DepartmentPage from './pages/DepartmentPage';
import SubCrimeTypePage from './pages/SubCrimeTypePage';
import ReportPage from './pages/ReportPage';
import CrimeTypePage from './pages/CrimeTypePage';
import BannerPage from './pages/BannerPage';
import DivPage from './pages/DivPage';
import AdminDivPage from './pages/AdminDivPage';
import AdminPage from './pages/AdminPage';
import ImagePage from './pages/ImagePage';
import FilePage from './pages/FilePage';
import AboutPage from './pages/AboutPage';
import FeedbackPage from './pages/FeedbackPage';
import RequestPage from './pages/RequestPage';
import FeedbackApprovedPage from './pages/FeedbackApprovedPage';
import FeedbackDeclinedPage from './pages/FeedbackDeclinedPage';

// ----------------------------------------------------------------------

export default function Router() {
  const role = localStorage.getItem('role');

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={role && role !== '1' ? '/dashboard/news' : '/dashboard/feedback'} />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'categories', element: <CategoryPage /> },
        { path: 'banner', element: <BannerPage /> },
        { path: 'about', element: <AboutPage /> },
        { path: 'crime-reports', element: <CrimePage /> },
        { path: 'news', element: <NewsPage /> },
        { path: 'team', element: <TeamPage /> },
        { path: 'feedback', element: <FeedbackPage /> },
        { path: 'feedback-approved', element: <FeedbackApprovedPage /> },
        { path: 'feedback-declined', element: <FeedbackDeclinedPage /> },
        { path: 'request', element: <RequestPage /> },
        { path: 'keys', element: <QuestionKeyPage /> },
        { path: 'report-types', element: <ReportTypePage /> },
        { path: 'department', element: <DepartmentPage /> },
        { path: 'sub-crime-types', element: <SubCrimeTypePage /> },
        { path: 'crimes', element: <ReportPage /> },
        { path: 'crime-types', element: <CrimeTypePage /> },
        { path: 'division', element: <DivPage /> },
        { path: 'admin-division', element: <AdminDivPage /> },
        { path: 'admin', element: <AdminPage /> },
        { path: 'images', element: <ImagePage /> },
        { path: 'files', element: <FilePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'reset-password',
      element: <ResetPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/news" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
