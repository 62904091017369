import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Popover,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  TablePagination,
  IconButton,
  CircularProgress,
  Grid,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { DepartmentCreate, DepartmentDelete } from '../sections/@dashboard/department';
// api
import API from '../api';

// ----------------------------------------------------------------------

export default function DepartmentPage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [selectedFeedback, setSelectedFeedback] = useState({});

  const [feedbacks, setFeedbacks] = useState([]);

  const fetchFeedbacks = async (page = 1, limit = 20) => {
    const response = await API.get(`/department?page=${page}&limit=${limit}`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setFeedbacks(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchFeedbacks(page, rowsPerPage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedFeedback(data);
    setOpen(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedFeedback({});
    fetchFeedbacks(page, rowsPerPage);
  };

  return (
    <>
      <Helmet>
        <title>Харьяалах газар</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Харьяалах газар
          </Typography>
          <DepartmentCreate resetPage={resetPage} />
        </Stack>

        <Scrollbar>
          <Card>
            <Grid container>
              <Grid item xs={6} />
              <Grid item xs={6}>
                {loading && <CircularProgress />}
              </Grid>
            </Grid>
            <TableContainer sx={{ minWidth: 400 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>№</TableCell>
                    <TableCell>Хэлтэс</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbacks.length > 0 ? (
                    feedbacks.map((rowData, index) => {
                      const { dep_id, dep_name } = rowData;
                      return (
                        <TableRow key={dep_id} hover>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap px={2}>
                                {index + 1}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle" noWrap px={2}>
                                {dep_name}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, rowData)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Өгөгдөл олдсонгүй
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20, 50, 100]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Scrollbar>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem sx={{ color: 'error.main' }}>
          <DepartmentDelete id={selectedFeedback.dep_id} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
