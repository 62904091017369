/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Grid,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { ImageSelector } from './NewsModal';
import Iconify from '../../../components/iconify';

import API, { API_URL } from '../../../api';

export default function NewsUpdate({ news, resetPage, categories }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const [subCategories, setSubCategories] = useState();
  const [catName, setCatName] = useState(null);
  const [subCatName, setSubCatName] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [checked, setChecked] = useState(false);
  const [status, setStatus] = useState(false);
  const [link, setLink] = useState('');
  const [commendable, setCommendable] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [otherNews, setOtherNews] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [publishDate, setPublishDate] = useState(dayjs());

  const [paths, setPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageSelect, setOpenImageSelect] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    const {
      n_content,
      n_desc,
      n_title,
      cat_name,
      sub_cat_name,
      sub_cat_id,
      n_video_link,
      show_news,
      is_new,
      posted_date,
      accept_comment,
      n_img,
      status: dbStatus,
      cat_id,
      show_img,
    } = news;
    setTitle(n_title);
    setContent(n_content);
    setDescription(n_desc);
    setSelectedSubCategory(sub_cat_id);
    setCatName(cat_name);
    setSubCatName(sub_cat_name);
    setLink(n_video_link);

    setCommendable(accept_comment !== 0);
    setIsNew(is_new !== 0);
    setOtherNews(show_news !== 0);
    setStatus(dbStatus !== 0);
    setShowImage(show_img !== 0);
    const setterDate = dayjs(new Date(posted_date));
    if (setterDate) setPublishDate(setterDate);
    if (n_img) {
      setPaths([`${API_URL}${n_img}`]);
    }

    if (news.cat_id) {
      const defaultCategory = categories.find((cat) => cat.cat_id === news.cat_id);
      if (defaultCategory) onSelectCategory(defaultCategory);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news]);

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();
    newsData.append('n_id', news.n_id);
    newsData.append('n_img', acceptedFiles[0] || news.n_img);
    newsData.append('n_title', title);
    newsData.append('n_desc', description);
    newsData.append('n_content', content);
    newsData.append('n_video_link', link);
    newsData.append('sub_cat_id', selectedSubCategory);
    newsData.append('n_files', acceptedPDFFiles[0] || news.n_files);
    newsData.append('status', status ? 1 : 0);
    newsData.append('accept_comment', commendable ? 1 : 0);
    newsData.append('show_news', otherNews ? 1 : 0);
    newsData.append('show_img', showImage ? 1 : 0);
    newsData.append('is_new', isNew ? 1 : 0);
    newsData.append('posted_date', dayjs(new Date(publishDate)).format());
    try {
      setLoading(true);
      const response = await API.put(`/admin`, newsData, config);
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetPage();
        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const onSelectCategory = (data) => {
    const { sub_cat_id, sub_cat_name } = data;
    const subIds = sub_cat_id.split(',');
    const subNames = sub_cat_name.split(',');
    const list = subIds.map((id, index) => ({ sub_cat_id: id, sub_cat_name: subNames[index] }));
    setSubCategories(list);
  };
  const onSelectSubCategory = (data) => {
    setSelectedSubCategory(data.sub_cat_id);
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSelectImage = (data) => {
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };
  const handlePickImage = () => {
    setOpenImageSelect(true);
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Мэдээ засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                label="Гарчиг"
                type="text"
                fullWidth
                autoFocus
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                label="Дэд гарчиг"
                type="text"
                fullWidth
              />
              <FormControl fullWidth sx={{ width: 1, my: 1 }}>
                <InputLabel id="category-box-label">Категори сонгох</InputLabel>
                <Select
                  labelId="category-box-label"
                  id="category-box"
                  defaultValue={news.cat_id}
                  label="Категори сонгох"
                >
                  {categories.map((option, index) => (
                    <MenuItem
                      onClick={() => {
                        onSelectCategory(option);
                      }}
                      key={index}
                      value={option.cat_id}
                    >
                      {option.cat_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ width: 1, mb: 1 }}>
                <InputLabel id="sub-category-box-label">Дэд категори сонгох</InputLabel>
                <Select
                  labelId="sub-category-box-label"
                  id="sub-category-box"
                  defaultValue={news.sub_cat_id}
                  label="Дэд категори сонгох"
                >
                  {subCategories?.map((option, index) => (
                    <MenuItem
                      onClick={() => {
                        onSelectSubCategory(option);
                      }}
                      key={index}
                      value={option.sub_cat_id}
                    >
                      {option.sub_cat_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <Autocomplete
                disablePortal
                id="category-box"
                isOptionEqualToValue={(option, value) => option.cat_id === value.cat_id}
                getOptionLabel={(option) => option.cat_name}
                options={categories || []}
                sx={{ width: 1, my: 1 }}
                defaultValue={{ cat_id: news.cat_id, cat_name: news.cat_name }}
                onChange={(_event, value) => onSelectCategory(value)}
                renderInput={(params) => <TextField {...params} label="Категори сонгох" />}
              />
              {subCategories?.length > 0 && (
                <Autocomplete
                  disablePortal
                  id="sub-category-box"
                  isOptionEqualToValue={(option, value) => option.sub_cat_id === value.sub_cat_id}
                  getOptionLabel={(option) => option.sub_cat_name}
                  options={subCategories}
                  sx={{ width: 1, mb: 1 }}
                  defaultValue={{ sub_cat_id: news.sub_cat_id, sub_cat_name: news.sub_cat_name }}
                  onChange={(_event, value) => onSelectSubCategory(value)}
                  renderInput={(params) => <TextField {...params} label="Дэд категори сонгох" />}
                />
              )} */}
              <Editor
                apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
                onInit={(_evt, editor) => {
                  setContent(editor.getContent());

                  const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
                  editor.editorContainer.appendChild(tinyAuxEl);
                }}
                value={content}
                onEditorChange={(curContent, _editor) => setContent(curContent)}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: `preview importcss searchreplace autolink autosave save 
                  directionality code visualblocks visualchars fullscreen 
                  image link media template codesample table charmap 
                  pagebreak nonbreaking anchor advlist 
                  lists wordcount help charmap emoticons table code`,
                  toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                  | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                  | outdent indent | numlist bullist 
                  | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                  |  save print table code
                  | insertfile image media template link anchor codesample | ltr rtl`,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  file_picker_types: 'file image media',
                  images_upload_handler: upImg,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Card variant="outlined" sx={{ mb: 1 }}>
                <Dialog open={openImageSelect} fullWidth maxWidth={false}>
                  <DialogTitle>Зургийн сан</DialogTitle>
                  <DialogContent
                    sx={{
                      py: 1,
                    }}
                  >
                    <ImageSelector handleSelectImage={handleSelectImage} />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenImageSelect(false)}>Цуцлах</Button>
                  </DialogActions>
                </Dialog>
                <Box m={1}>
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    Зураг
                  </div>
                  <Button onClick={handlePickImage}>Зургийн сангаас сонгох</Button>
                  {paths.length > 0 ? (
                    paths.map((path, key) => (
                      <Box
                        key={path}
                        sx={{
                          border: '1px solid #ebebeb',
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <img src={path} alt={imageFiles[key] || 'no photo'} width="100" height="100" />
                        <Button onClick={() => setPaths([])}>Зураг солих</Button>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
                        <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
                      </div>
                    </Box>
                  )}
                  <div
                    style={{
                      margin: '0.5rem 0.75rem ',
                    }}
                  >
                    PDF Файл
                  </div>
                  <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                    <div {...getPDFRootProps({ className: 'dropzone' })}>
                      <input {...getPDFInputProps()} />
                      <p>PDF файлыг энд чирж, эсвэл энд товшино уу.</p>
                      <em>(Зөвхөн *.pdf файлыг хүлээн авна)</em>
                      <em>(Дахин дарж солино уу.)</em>
                    </div>
                    {PDFFiles.length > 0 && (
                      <aside>
                        <h4>PDF:</h4>
                        <p>{PDFFiles}</p>
                      </aside>
                    )}
                  </Box>
                </Box>
              </Card>
              <Card variant="outlined">
                <Box m={1}>
                  <div>Тохиргоо</div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={status}
                          onChange={(event) => {
                            setStatus(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={status ? 'Нийтлэгдсэн' : 'Хүлээгдэж буй'}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={checked}
                          onChange={(event) => {
                            setChecked(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="ВИДЕО МЭДЭЭ"
                    />
                  </FormGroup>

                  {checked && (
                    <TextField
                      sx={{
                        my: 1,
                      }}
                      value={link}
                      onChange={(event) => setLink(event.target.value)}
                      label="Линк"
                      type="text"
                      fullWidth
                      autoFocus
                    />
                  )}
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={commendable}
                          onChange={(event) => {
                            setCommendable(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Сэтгэгдэл ${commendable ? 'авна' : 'авахгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isNew}
                          onChange={(event) => {
                            setIsNew(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Шинэ мэдээ: ${isNew ? 'Тийм' : 'Үгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={otherNews}
                          onChange={(event) => {
                            setOtherNews(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Бусад мэдээ: ${otherNews ? 'Харагдана' : 'Харагдахгүй'}`}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={showImage}
                          onChange={(event) => {
                            setShowImage(event.target.checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={`Мэдээний зураг: ${showImage ? 'Харагдана' : 'Харагдахгүй'}`}
                    />
                  </FormGroup>
                  <DatePicker
                    sx={{
                      my: 1,
                    }}
                    value={publishDate}
                    onChange={(newValue) => setPublishDate(newValue)}
                    label="Нийтлэгдэх огноо"
                    type="text"
                    fullWidth
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
          {/* <TextField
            sx={{
              my: 2,
            }}
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Гарчиг"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              mb: 2,
            }}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            label="Дэд гарчиг"
            type="text"
            fullWidth
          />
          <Editor
            apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
            onInit={(evt, editor) => setContent(editor.getContent())}
            value={content}
            onEditorChange={(curContent, editor) => setContent(curContent)}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'print',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'preview',
                'paste',
                'code',
                'help',
                'wordcount',
                'image',
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic underline | forecolor backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist | outdent indent | ' +
                'image | ' +
                'removeformat | help | table | code',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              file_picker_types: 'file image media',
              images_upload_handler: upImg,
            }}
          />
          <Autocomplete
            disablePortal
            id="category-box"
            isOptionEqualToValue={(option, value) => option.cat_id === value.cat_id}
            getOptionLabel={(option) => option.cat_name}
            options={categories || []}
            sx={{ width: 1, my: 2 }}
            defaultValue={{ cat_id: news.cat_id, cat_name: news.cat_name }}
            onChange={(_event, value) => onSelectCategory(value)}
            renderInput={(params) => <TextField {...params} label="Категори сонгох" />}
          />
          {subCategories?.length > 0 && (
            <Autocomplete
              disablePortal
              id="sub-category-box"
              isOptionEqualToValue={(option, value) => option.sub_cat_id === value.sub_cat_id}
              getOptionLabel={(option) => option.sub_cat_name}
              options={subCategories}
              sx={{ width: 1, mb: 2 }}
              defaultValue={{ sub_cat_id: news.sub_cat_id, sub_cat_name: news.sub_cat_name }}
              onChange={(_event, value) => onSelectSubCategory(value)}
              renderInput={(params) => <TextField {...params} label="Дэд категори сонгох" />}
            />
          )}
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={(event) => {
                    setChecked(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="ВИДЕО МЭДЭЭ"
            />
          </FormGroup>
          {checked && (
            <TextField
              sx={{
                my: 2,
              }}
              value={link}
              onChange={(event) => setLink(event.target.value)}
              label="Линк"
              type="text"
              fullWidth
              autoFocus
            />
          )}
          <div
            style={{
              margin: '0.5rem 0.75rem ',
            }}
          >
            Зураг
          </div>
          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Зурган файлыг энд чирж, эсвэл энд товшино уу.</p>
              <em>(Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
            </div>
            {imageFiles.length > 0 && (
              <aside>
                <h4>Зураг</h4>
                <p>{imageFiles}</p>
              </aside>
            )}
          </Box>
          <div
            style={{
              margin: '0.5rem 0.75rem ',
            }}
          >
            PDF Файл
          </div>
          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getPDFRootProps({ className: 'dropzone' })}>
              <input {...getPDFInputProps()} />
              <p>PDF файлыг энд чирж, эсвэл энд товшино уу.</p>
              <em>(Зөвхөн *.pdf файлыг хүлээн авна)</em>
            </div>
            {PDFFiles.length > 0 && (
              <aside>
                <h4>PDF:</h4>
                <p>{PDFFiles}</p>
              </aside>
            )}
          </Box>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={status}
                  onChange={(event) => {
                    setStatus(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Төлөв солих"
            />
          </FormGroup> */}
          {/* <div
            style={{
              margin: '0.5rem 0.75rem ',
              textTransform: 'uppercase',
            }}
          >
            {status ? 'Нийтлэгдсэн' : 'Хүлээгдэж буй'}
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
