/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function AboutUpdate({ resetPage, selectedItem }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [content, setContent] = useState('');
  const [name, setName] = useState('');

  const fetchContentById = async (id) => {
    const response = await API.get(`/admin/about/${id}`);

    const {
      data: { data },
    } = response;

    setContent(data.about_content);
  };

  useEffect(() => {
    const { id, name } = selectedItem;
    setName(name);
    if (id) fetchContentById(id);
  }, [selectedItem]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await API.put(`/admin/about`, {
        about_content: content,
        about_type: selectedItem.id,
      });
      const {
        data: { success, message },
      } = response;
      if (success === 1) {
        resetPage();

        handleClose();
        toast.success('Амжилттай', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, _progress) =>
    new Promise((resolve, _reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        {name} засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>{name} засах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Editor
            className="z-[9999]"
            apiKey="ez2jtrxf1zflp4x4hcawemzw5eywl3zfqzfix546oyvks7tk"
            onInit={(_evt, editor) => {
              setContent(editor.getContent());

              const tinyAuxEl = document.querySelector('.tox-tinymce-aux');
              editor.editorContainer.appendChild(tinyAuxEl);
            }}
            value={content}
            onEditorChange={(curContent, _editor) => {
              setContent(curContent);
            }}
            init={{
              height: 500,
              menubar: false,
              readonly: false,
              plugins: `preview importcss searchreplace autolink autosave save 
                    directionality code visualblocks visualchars fullscreen 
                    image link media template codesample table charmap 
                    pagebreak nonbreaking anchor advlist 
                    lists wordcount help charmap emoticons table code`,
              toolbar: `undo redo | preview fullscreen | bold italic underline strikethrough 
                    | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify 
                    | outdent indent | numlist bullist 
                    | forecolor backcolor removeformat | pagebreak | charmap emoticons 
                    |  save print table code
                    | insertfile image media template link anchor codesample | ltr rtl`,
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              file_picker_types: 'file image media',
              images_upload_handler: upImg,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
