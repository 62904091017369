/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';

// @mui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box } from '@mui/material';
import { toast } from 'react-toastify';
// components
import Iconify from '../components/iconify/Iconify';

import API from '../api';

export default function CrimeNew({ resetAll }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {},
    maxFiles: 1,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const notify = (message) =>
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const newsData = new FormData();

    newsData.append('excel', acceptedFiles[0]);

    try {
      setLoading(true);
      const response = await API.post(`/crime/upload`, newsData, config);
      const {
        data: { success },
      } = response;
      if (success === 1) {
        handleClose();
        toast.success('Success', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        resetAll();
      }
    } catch (error) {
      const {
        response: {
          data: { message },
        },
      } = error;
      console.error(message);
      notify(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        startIcon={<Iconify icon="eva:plus-fill" />}
        sx={{ justifyContent: 'end' }}
        onClick={handleClickOpen}
      >
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Excel нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <div
            style={{
              margin: '0.5rem 0.75rem ',
            }}
          >
            Excel файл
          </div>
          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Excel файлыг энд чирж, эсвэл энд товшино уу.</p>
              <em>(Зөвхөн *.xlsx хүлээн авна)</em>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
