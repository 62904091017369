/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-extra-boolean-cast */
import axios from 'axios';

const BASE_URL = {
  dev: 'http://localhost:8005',
  // dev: 'https://43.231.113.209',
  prod: 'https://mini-khutuch.mn',
};

const BASE_API_URL = {
  // dev: `https://43.231.113.209/api`,
  dev: 'http://localhost:8005/api',
  prod: 'https://mini-khutuch.mn/api',
};

export const API_URL = BASE_URL.prod;
export const EXPORT_API_URL = BASE_API_URL.prod;
let customAxios = axios.create({
  baseURL: EXPORT_API_URL,
});

const handleLocalStorage = () => {
  window.dispatchEvent(new Event('storage'));
};

window.addEventListener('storage', () => {
  const accessToken = localStorage.getItem('accessToken');
  customAxios = axios.create({
    baseURL: EXPORT_API_URL,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
});
handleLocalStorage();

customAxios.interceptors.response.use(null, (error) => {
  if (error.config && error.response && error.response.status === 401) {
    const token = localStorage.getItem('accessToken');
    error.config.headers.common = { Authorization: `Bearer ${token}` };
    return axios.request(error.config);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default customAxios;
