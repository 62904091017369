/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { toast } from 'react-toastify';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

const filter = createFilterOptions();

// const ROLES = [
//   { label: 'Super admin', value: 1 },
//   { label: 'Admin', value: 2 },
// ];

export default function FeedbackCreate({ resetPage, divisions = [] }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  const [selectedDiv, setSelectedDiv] = useState(null);

  const handleClickOpen = () => {
    setName('');
    setSelectedDiv(null);
    setPassword('');
    setOpen(true);
  };

  const handleClose = () => {
    setName('');
    setSelectedDiv(null);
    setPassword('');
    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // let requestBody = {
      //   username: name,
      //   password,
      //   role,
      //   d_id: selectedDiv.d_id,
      // };

      // if (!selectedDiv?.d_id) {}
      const requestBody = {
        username: name,
        password,
        role: 2,
        d_name: selectedDiv?.d_name,
      };

      const {
        data: { success, message },
      } = await API.post(`/auth/user`, requestBody);
      if (success === 1) {
        resetPage();
        handleClose();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй дахин оролдоно уу ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Хэрэглэгч нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Autocomplete
            value={selectedDiv?.d_name}
            onChange={(event, newValue) => {
              if (typeof newValue === 'string') {
                setSelectedDiv({
                  d_name: newValue,
                });
              } else {
                setSelectedDiv(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some((option) => inputValue === option.d_name);
              if (inputValue !== '' && !isExisting) {
                filtered.push({
                  inputValue,
                  d_name: `${inputValue}`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            handleHomeEndKeys
            id="free-solo-with-text-division"
            options={divisions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.d_name;
            }}
            renderOption={(props, option) => <li {...props}>{option.d_name || '--- Өгөгдөл алдаатай ---'}</li>}
            sx={{ width: 1 }}
            freeSolo
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Харьяа хэлтэс сонгох (Шинээр нэмэх бол бичнэ үү.)" />
            )}
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            label="Нэр"
            type="text"
            fullWidth
            autoFocus
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            label="Нууц үг"
            type="text"
            fullWidth
          />
          {/* <Autocomplete
            disablePortal
            id="category-box"
            options={ROLES}
            sx={{ width: 1, mb: 2 }}
            onChange={(_, role) => setRole(role.value)}
            renderInput={(params) => <TextField {...params} label="Төрөл сонгох" />}
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
