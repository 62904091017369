/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Popover,
  MenuItem,
  Typography,
  TablePagination,
  Grid,
  Box,
  Avatar,
  IconButton,
  Button,
  CircularProgress,
} from '@mui/material';
import fileDownload from 'js-file-download';

// components
import Scrollbar from '../components/scrollbar';
import Iconify from '../components/iconify';

// sections
import { ImageCreate, ImageDelete } from '../sections/@dashboard/image';
// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

export default function ImagePage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [selectedImage, setSelectedImage] = useState({});

  const [images, setImages] = useState([]);
  const [text, setText] = useState('');

  const fetchImages = async (page = 1, limit = 20) => {
    // const response = await API.get(`/upload/images?page=${page}&limit=${limit}`)
    const response = await API.get(`/upload/search?page=${page}&limit=${limit}&text=${text}$is_image=1`);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setImages(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchImages(page, rowsPerPage);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedImage(data);
    setOpen(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedImage({});
    fetchImages(page, rowsPerPage);
  };

  return (
    <>
      <Helmet>
        <title>Зургийн сан</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Зургийн сан
        </Typography>
        <ImageCreate resetPage={resetPage} />
      </Stack>

      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress />}

        <Grid container alignItems="center" justifyContent="center" m={5} p={5} gap={1}>
          {images && images.length > 0 ? (
            <>
              {images.map((imageData, key) => {
                const { file_path, file_name } = imageData;
                return (
                  <Grid
                    key={key}
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ebebeb',
                      position: 'relative',
                    }}
                    p={1}
                  >
                    <Avatar
                      sx={{ bgcolor: '#ebebeb', width: '100px', height: '100px' }}
                      alt="img"
                      src={`${API_URL}${file_path}`}
                      variant="square"
                    />
                    <Box sx={{ textAlign: 'center', fontSize: '12px' }}>{file_name || file_path.split('/').pop()}</Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        right: 1,
                      }}
                    >
                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, imageData)}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
              No Images
            </Grid>
          )}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <a
            href={`${API_URL}/api/upload/download?file_path=${selectedImage.file_path}`}
            target="_blank"
            download
            rel="noreferrer"
            style={{
              width: '100%',
              textDecoration: 'none',
            }}
          >
            <Button fullWidth sx={{ justifyContent: 'start' }} startIcon={<Iconify icon={'ic:file-download'} />}>
              Татах
            </Button>
          </a>
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <ImageDelete id={selectedImage?.file_id} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
