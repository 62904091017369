/* eslint-disable react/prop-types */
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

// @mui
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function ImageCreate({ resetPage }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [paths, setPaths] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setPaths(acceptedFiles.map((file) => URL.createObjectURL(file)));
    },
    [setPaths]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop,
  });

  const imageFiles = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const removeImages = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setPaths([])
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    removeImages()
  };

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const imagesData = new FormData();

    acceptedFiles.forEach((imageFile) => {
      imagesData.append('files', imageFile);
    });

    try {
      setLoading(true);

      const response = await API.post(`/upload`, imagesData, config);
      const {
        data: { success, message },
      } = response;

      if (success === 1) {
        resetPage();
        handleClose();
        removeImages();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Зургууд нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <div
            style={{
              margin: '0.5rem 0.75rem ',
            }}
          >
            Зураг
          </div>
          <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <p>Зурган файлыг энд чирж, эсвэл энд товшино уу. (Олон зураг зэрэг оруулж болно)</p>
              <em>(*Зөвхөн *.jpeg болон *.png зургийг хүлээн авна)</em>
            </div>
            {paths.length > 0 && (
              <aside>
                <h4>Зураг</h4>
                <p>{imageFiles}</p>
              </aside>
            )}
            <Grid container>
              {paths.map((path, key) => (
                <Grid key={path} item xs={2}>
                  <img src={path} alt={imageFiles[key]} width="75" height="75" />
                </Grid>
              ))}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
