/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import React, { useEffect, useMemo, useState } from 'react';
// @mui
import {
  Card,
  Stack,
  Popover,
  MenuItem,
  Typography,
  TablePagination,
  Grid,
  Box,
  Avatar,
  IconButton,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';

// components
import Scrollbar from '../components/scrollbar';
import Iconify from '../components/iconify';

// sections
import { FileCreate, FileDelete, FileUpdate } from '../sections/@dashboard/file';
import FolderCreate from '../sections/@dashboard/file/FolderCreate';
import FolderDelete from '../sections/@dashboard/file/FolderDelete';

// api
import API, { API_URL } from '../api';

// ----------------------------------------------------------------------

export default function FilePage() {
  const [open, setOpen] = useState(null);

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [total, setTotal] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [selectedFile, setSelectedFile] = useState({});
  const [selectedFolder, setSelectedFolder] = useState(null);

  const [images, setImages] = useState([]);
  const [selectedFolderList, setSelectedFolderList] = useState([]);
  const [text, setText] = useState('');

  // will fetch folders and images
  const fetchData = async (params) => {
    const { page = 1, limit = 20, textQuery = '', folder_id, isSearch = false } = params;
    let queryString = `/upload/search?page=${page}&limit=${limit}&text=${textQuery}&isSearch=${isSearch}`;
    if (folder_id) {
      queryString += `&folder_id=${folder_id}`;
    }
    const response = await API.get(queryString);
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setTotal(total);
    setImages(data);
  };

  useEffect(() => {
    try {
      setLoading(true);
      fetchData({
        page,
        limit: rowsPerPage,
      });
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage]);

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, data) => {
    setSelectedFile(data);
    setOpen(event.currentTarget);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleClickFolder = (folder) => {
    pushToStack(folder);
    setPage(0);
    setText('');
    fetchData({ folder, page: 0, limit: rowsPerPage, folder_id: folder?.file_id });
  };

  const handleSearch = (textQuery) => {
    setText(textQuery);
    const isSearch = textQuery !== '';
    if (hasSelectedFolder) {
      fetchData({
        selectedFolder,
        page: 0,
        limit: rowsPerPage,
        folder_id: selectedFolder?.file_id,
        textQuery,
      });
    } else {
      fetchData({
        page,
        limit: rowsPerPage,
        textQuery,
        isSearch,
      });
    }
  };

  const handleBack = () => {
    popFromStack();
  };

  const resetPage = () => {
    setOpen(null);
    setSelectedFile(null);
    setSelectedFolder(null);
    fetchData({
      page: 0,
      limit: rowsPerPage,
    });
  };

  const hasSelectedFolder = useMemo(() => selectedFolder && selectedFolder !== {});

  const [folderName, setFolderName] = useState('');
  useEffect(() => {
    if (selectedFolder) setFolderName(selectedFolder.file_name);
  }, [selectedFolder]);

  // handle selected folders history in this stack state
  const [stack, setStack] = useState([]);

  const pushToStack = (folder) => {
    // console.log(folder);
    setStack([...stack, folder]);
    setSelectedFolder(folder);
  };

  const popFromStack = () => {
    if (stack.length === 0) {
      // alert('Stack is empty!');
      // resetPage();
      // setSelectedFile();
      return;
    }
    const newStack = stack.slice(0, stack.length - 1);
    // preFolder is previous selected folder
    const preFolder = newStack[newStack.length - 1];
    // console.log(preFolder, selectedFolder);
    if (preFolder) {
      setSelectedFolder(preFolder);
      fetchData({ preFolder, page: 0, limit: rowsPerPage, folder_id: preFolder?.file_id });

      // console.log('orjogo sda min');
    } else {
      setSelectedFile(null);
      resetPage();
    }
    setStack(newStack);
  };

  const peekStack = () => {
    if (stack.length === 0) {
      return;
    }
    const topElement = stack[stack.length - 1];
  };

  const handleFolderNameChange = async (newName) => {
    setFolderName(newName);
    await API.put(`/upload`, {
      file_id: selectedFolder.file_id,
      file_name: newName,
      folder_id: selectedFolder.folder_id,
    });
  };

  const copyToClipboard = (copyText) => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        toast.success('Амжилттай хуулагдлаа. ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      })
      .catch((error) => console.error('Failed to copy:', error));
  };

  const [changeFolderName, setChangeFolderName] = useState(false);

  return (
    <>
      <Helmet>
        <title>Файл сан</title>
      </Helmet>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Файл сан
        </Typography>
        <FileCreate selectedFolder={selectedFolder} resetPage={resetPage} />
      </Stack>

      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container p={2}>
          <Grid item xs={4}>
            <TextField
              value={text}
              onChange={(event) => handleSearch(event.target.value)}
              label=" Хайх"
              type="text"
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid
            item
            xs={8}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              ml="auto"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box>
                <FolderCreate resetAll={resetPage} selectedFolder={selectedFolder} />
              </Box>
              <Box>{hasSelectedFolder && <FolderDelete resetPage={resetPage} id={selectedFolder.file_id} />}</Box>
            </Box>
          </Grid>
          {hasSelectedFolder && (
            <React.Fragment key={selectedFolder.file_id}>
              <Grid item xs={4} p={1}>
                <IconButton size="large" color="inherit" onClick={(event) => handleBack()}>
                  <Iconify icon={'eva:arrow-back-fill'} />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={8}
                p={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box ml="auto">
                  <input
                    type="text"
                    value={folderName}
                    onChange={(event) => handleFolderNameChange(event.target.value)}
                    style={
                      changeFolderName
                        ? { py: 1 }
                        : {
                            p: 0,
                            border: 'none',
                            outline: 'none',
                            ':focus': {
                              border: '1px solid #ebebeb',
                              boxShadow: '0 0 5px blue',
                            },
                          }
                    }
                  />
                  <IconButton size="large" color="inherit" onClick={() => setChangeFolderName(!changeFolderName)}>
                    {changeFolderName ? (
                      <Iconify icon={'material-symbols:close'} />
                    ) : (
                      <Iconify icon={'material-symbols:edit'} />
                    )}
                  </IconButton>
                </Box>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        {loading && <CircularProgress />}

        <Grid container alignItems="center" justifyContent="center" m={5} p={5} pt={0} mt={0} gap={1}>
          {images && images.length > 0 ? (
            <>
              {images.map((imageData, key) => {
                const { file_path, file_name, file_type } = imageData;
                if (file_type === 'folder') {
                  return (
                    <Grid
                      key={key}
                      item
                      xs={2}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '1px solid #ebebeb',
                        position: 'relative',
                      }}
                      p={1}
                      onClick={() => handleClickFolder(imageData)}
                    >
                      <Iconify icon="ic:twotone-folder" sx={{ width: '100px', height: '100px' }} />

                      {/* this name can be modeless */}
                      <Box sx={{ textAlign: 'center', fontSize: '12px' }}>{file_name}</Box>
                    </Grid>
                  );
                }
                return (
                  <Grid
                    key={key}
                    item
                    xs={2}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ebebeb',
                      position: 'relative',
                    }}
                    p={1}
                  >
                    <Avatar
                      sx={{ bgcolor: '#ebebeb', width: '100px', height: '100px' }}
                      alt="img"
                      src={`${API_URL}${file_path}`}
                      variant="square"
                    />
                    <Box
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '100px',
                      }}
                    >
                      {file_name || file_path?.split('/').pop()}
                    </Box>
                    <Box
                      sx={{
                        position: 'absolute',

                        top: 0,
                        right: 1,
                      }}
                    >
                      <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, imageData)}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Grid item xs={3} sx={{ textAlign: 'center' }}>
              No Files
            </Grid>
          )}
        </Grid>

        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <a
            href={`${API_URL}/api/upload/download?file_path=${selectedFile?.file_path}`}
            target="_blank"
            download
            rel="noreferrer"
            style={{
              width: '100%',
              textDecoration: 'none',
            }}
          >
            <Button fullWidth sx={{ justifyContent: 'start' }} startIcon={<Iconify icon={'ic:file-download'} />}>
              Татах
            </Button>
          </a>
        </MenuItem>
        {selectedFile?.file_type === 'file' && (
          <MenuItem onClick={() => copyToClipboard(`${API_URL}/${selectedFile?.file_path}`)}>
            <Button fullWidth sx={{ justifyContent: 'start' }} startIcon={<Iconify icon={'iconamoon:copy'} />}>
              Хуулах
            </Button>
          </MenuItem>
        )}
        <MenuItem>
          <FileUpdate file={selectedFile} resetPage={resetPage} />
        </MenuItem>
        <MenuItem sx={{ color: 'error.main' }}>
          <FileDelete id={selectedFile?.file_id} resetPage={resetPage} />
        </MenuItem>
      </Popover>
    </>
  );
}
