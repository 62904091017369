/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { format } from 'date-fns';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { NewsListHead, NewsListToolbar, NewsModal, NewsUpdate } from '../sections/@dashboard/news';
// api
import API from '../api';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'index', label: '№', alignRight: false },
  { id: 'n_id', label: 'id  ', alignRight: false },
  { id: 'title', label: 'Гарчиг', alignRight: false },
  { id: 'a_username', label: 'Хэрэглэгч', alignRight: false },
  { id: 'sub_cat_name', label: 'Категори', alignRight: false },
  { id: 'n_viewcount', label: 'Үзсэн тоо', alignRight: false },
  // { id: 'description', label: 'Дэд гарчиг', alignRight: false },
  // { id: 'createdAt', label: 'Бүртгэсэн огноо', alignRight: false },
  { id: 'posted_date', label: 'Нийтлэсэн огноо', alignRight: false },
  { id: 'status', label: 'Төлөв', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function NewsPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [loading, setLoading] = useState(false);

  // const [selectedCategory, setSelectedCategory] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [news, setNews] = useState([]);

  const [selectedNews, setSelectedNews] = useState({});

  const [totalNewsCount, setTotalNewsCount] = useState(0);

  const [catID, setCatID] = useState(null);
  const [subCatID, setSubCatID] = useState(null);

  const [payload, setPayload] = useState(null);

  const fetchNews = async ({ page = 1, limit = 20, payload }) => {
    const response = await API.post(`/admin/filter?page=${page}&limit=${limit}`, {
      startDate: '1900-01-01',
      endDate: '2999-01-01',
      n_title: '',
      n_id: '',
      cat_id: '',
      sub_cat_id: '',
      a_username: '',
    });
    const {
      data: {
        data,
        pagination: { total },
      },
    } = response;
    setNews(data);
    setTotalNewsCount(total);
  };

  const filterSearch = async (payload) => {
    setPayload(payload);
    const {
      title,
      newsId,
      selectedUser,
      startDate,
      endDate,
      selectedCategory,
      selectedSubCategory,
      page = 1,
      limit = rowsPerPage,
    } = payload;
    const formattedStartDate = dayjs(new Date(startDate)).format('YYYY-MM-DD');
    const formattedEndDate = dayjs(new Date(endDate)).format('YYYY-MM-DD');

    const response = await API.post(`/admin/filter?page=${page}&limit=${limit}`, {
      n_title: title,
      n_id: newsId,
      cat_id: selectedCategory?.cat_id || '',
      sub_cat_id: selectedSubCategory?.sub_cat_id || '',
      a_username: selectedUser?.a_username || '',
      startDate: startDate ? formattedStartDate : '1900-01-01',
      endDate: formattedEndDate,
    });
    const {
      data: {
        data,
        success,
        pagination: { total },
      },
    } = response;
    if (success === 1) {
      setNews(data);
      setTotalNewsCount(total);
    }
  };

  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    const response = await API.get('/category');
    const {
      data: { data, success },
    } = response;
    if (success === 1) setCategories(data);
  };

  const resetPage = () => {
    setPage(0);
    setPayload(null);
    setSelectedNews({});
    setOpen(false);
    try {
      setLoading(true);
      fetchNews({ page, limit: rowsPerPage });
      fetchCategories();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);
      if (payload) {
        filterSearch({ ...payload, page: page + 1, limit: rowsPerPage });
      } else {
        // fetchNews({ payload, page: page + 1, limit: rowsPerPage });
      }
      fetchCategories();
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  const handleDelete = async () => {
    // todo: call api
    try {
      const response = await API.delete(`/admin/${selectedNews.n_id}`);
      const {
        data: { success },
      } = response;

      if (success === 1) {
        handleCloseMenu();
        fetchNews(page, rowsPerPage);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleOpenMenu = (event, selectedNews) => {
    setOpen(event.currentTarget);
    setSelectedNews(selectedNews);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const onSelectCategory = (category) => {
    setPage(0);
    // setSelectedCategory(category);
  };

  return (
    <>
      <Helmet>
        <title> Мэдээ </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Мэдээ
          </Typography>

          <NewsModal resetPage={resetPage} categories={categories} />
        </Stack>

        <Card>
          <NewsListToolbar
            resetPage={resetPage}
            onSelectCategory={onSelectCategory}
            categories={categories}
            filterSearch={filterSearch}
          />
          {loading ? (
            <CircularProgress />
          ) : (
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <NewsListHead headLabel={TABLE_HEAD} rowCount={news.length} />
                  <TableBody>
                    {news.length > 0 ? (
                      news.map((row, index) => {
                        const {
                          n_id,
                          n_title,
                          sub_cat_name,
                          cat_name,
                          createdAt,
                          status,
                          n_viewcount,
                          a_username,
                          posted_date,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={n_id}
                            sx={{
                              position: 'relative',
                            }}
                          >
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {index + 1}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap px={2}>
                                  {n_id}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {n_title || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {n_content ? (
                                    <>
                                      {n_content && n_content?.slice(0, 15)} {n_content?.length > 15 && '...'}
                                    </>
                                  ) : (
                                    '---'
                                  )}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {a_username || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {cat_name}
                                  <br />
                                  {sub_cat_name || '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" px={2}>
                                  {n_viewcount}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2">{n_desc || '...'}</Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {posted_date ? format(new Date(posted_date), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {updated_at ? format(new Date(updated_at), 'yyyy-MM-dd') : '---'}
                                </Typography>
                              </Stack>
                            </TableCell> */}
                            <TableCell align="left">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                <Typography variant="subtitle2" noWrap>
                                  {status === 0 ? 'Хүлээгдэж буй' : 'Нийтлэгдсэн'}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align="right">
                              <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event, row)}>
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Өгөгдөл олдсонгүй
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          )}

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalNewsCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <NewsUpdate news={selectedNews} resetPage={resetPage} categories={categories} />
        </MenuItem>

        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Устгах
        </MenuItem>
      </Popover>
    </>
  );
}
