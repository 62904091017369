/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
import { Editor } from '@tinymce/tinymce-react';
// @mui
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Box,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Grid,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// components
import { ImageSelector } from './RequestModal';
import Iconify from '../../../components/iconify';

import message from '../../../utils/sendMessage';

import API, { API_URL } from '../../../api';

export default function RequestUpdate({ request, resetPage, archive }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState('');
  const [categoryId, setcategoryId] = useState('');
  const [createdAt, setcreatedAt] = useState('');
  const [statuses, setStatuses] = useState(['Хүлээгдэж байгаа', 'Зөвшөөрөгдсөн', 'Цуцлагдсан']);
  const [messageValue, setMessageValue] = useState(null);
  const [deadline, setdeadline] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [feedbackNumber, setCfeedbackNumber] = useState(false);
  const [feedbackText, setfeedbackText] = useState(false);
  const [firstname, setfirstname] = useState('');
  const [phoneNumber, setphoneNumber] = useState(false);
  const [_id, set_id] = useState(false);
  const [registerNumber, setregisterNumber] = useState(false);
  const [showImage, setShowImage] = useState(false);

  const [publishDate, setPublishDate] = useState(dayjs());

  const [paths, setPaths] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null);
  const [openImageSelect, setOpenImageSelect] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    console.log(request);
    const {
      address,
      categoryId,
      createdAt,
      deadline,
      feedbackNumber,
      feedbackText,
      firstname,
      phoneNumber,
      _id,
      registerNumber,
      status,
    } = request;
    setAddress(address);
    setcategoryId(categoryId ? categoryId.title : '');
    setcreatedAt(createdAt);
    setdeadline(deadline);
    setCfeedbackNumber(feedbackNumber);
    setfeedbackText(feedbackText);
    setfirstname(firstname);
    setphoneNumber(phoneNumber);
    set_id(_id);
    setregisterNumber(registerNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [request]);

  const {
    acceptedFiles: acceptedPDFFiles,
    getRootProps: getPDFRootProps,
    getInputProps: getPDFInputProps,
  } = useDropzone({
    accept: {},
    maxFiles: 1,
  });

  const PDFFiles = acceptedPDFFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAddress('');
    setcategoryId('');
    setcreatedAt('');
    setdeadline('');
    setCfeedbackNumber('');
    setfeedbackText('');
    setfirstname('');
    setphoneNumber('');
    set_id('');
    setregisterNumber('');
  };
  const handleSubmit = async () => {
    // await message(request.phoneNumber, messageValue).then(async (messageResponse) => {
    //   if (messageResponse.data.status === 200) {
    //     toast.success('Амжилттай', {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: 'colored',
    //     });
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const feedbackData = new FormData();
    feedbackData.append('status', selectedStatus);
    try {
      setLoading(true);
      const response = await API.post(`/govService/feedback/${request._id}`, feedbackData, config);
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        resetPage();
        handleClose();
        toast.success(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
    //   } else {
    //     toast.error(messageResponse.message, {
    //       position: 'top-right',
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: 'colored',
    //     });
    //   }
    // });
  };

  const onSelectCategory = (data) => {
    const { sub_cat_id, sub_cat_name } = data;
    const subIds = sub_cat_id.split(',');
    const subNames = sub_cat_name.split(',');
    const list = subIds.map((id, index) => ({ sub_cat_id: id, sub_cat_name: subNames[index] }));
    setStatuses(list);
  };
  const onSelectSubCategory = (data) => {
    console.log(data);
    setSelectedStatus(data);
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const upImg = (blobInfo, progress) =>
    new Promise((resolve, reject) => {
      blobToBase64(blobInfo.blob()).then((res) => {
        resolve(res);
      });
    });

  const handleSelectImage = (data) => {
    setSelectedImage(data);
    setOpenImageSelect(false);
    setPaths([]);
    setPaths([`${API_URL}${data.file_path}`]);
  };
  const handlePickImage = () => {
    setOpenImageSelect(true);
  };

  return (
    <>
      <Button
        fullWidth
        sx={{ justifyContent: 'start' }}
        startIcon={<Iconify icon="eva:checkmark-fill" />}
        onClick={handleClickOpen}
      >
        Засах
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={false}>
        <DialogTitle>Хүсэлт хариулах</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container spacing={4} paddingTop={1}>
            <Grid item xs={8}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={firstname}
                label="Хүсэлт гаргагчийн нэр"
                type="text"
                disabled="true"
                fullWidth
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={categoryId}
                disabled="true"
                label="Хүсэлт илгээсэн цэс"
                type="text"
                fullWidth
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={phoneNumber}
                disabled="true"
                label="Хүсэлт гаргагчийн утасны дугаар"
                type="text"
                fullWidth
              />
              <FormControl fullWidth sx={{ width: 1, mb: 1 }}>
                <InputLabel id="sub-category-box-label">Хүсэлтийн төлөв сонгох</InputLabel>
                <Select
                  labelId="sub-category-box-label"
                  id="sub-category-box"
                  defaultValue={request.status}
                  label="Хүсэлтийн төлөв сонгох"
                >
                  {statuses?.map((option, index) => (
                    <MenuItem
                      onClick={() => {
                        onSelectSubCategory(option);
                      }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                sx={{
                  mb: 1,
                }}
                multiline
                rows={4}
                value={messageValue}
                onChange={(event) => setMessageValue(event.target.value)}
                label="Хүсэлт гаргагчид илгээх мессеж утга"
                type="text"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={{
                  mb: 1,
                }}
                value={feedbackNumber}
                disabled="true"
                label="Хүсэлтийн дугаар"
                type="text"
                fullWidth
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                multiline
                rows={4}
                value={feedbackText}
                disabled="true"
                label="Хүсэлтийн дэлгэрэнгүй утга"
                type="text"
                fullWidth
              />
              <TextField
                sx={{
                  mb: 1,
                }}
                value={registerNumber}
                disabled="true"
                label="Хүсэлт гаргагчийн регистрийн дугаар"
                type="text"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
