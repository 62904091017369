// eslint-disable-next-line import/no-import-module-exports
import axios from 'axios';

const message = async (phone, id, status, message) => {
  const token = 'e2ee5feacc2c750f460e6b0ab501af6bab6ce58a';

  // Construct the URL with placeholders for recipient and message
  const url = `http://web2sms.skytel.mn/apiSend`;

  // Define the data to send in the request
  const data = {
    token,
    sendto: phone,
    message: `Мини-хөтөч киоск 
    Таны ${id}-дугаартай хүсэлтийг ${status}. 
    ${message}`,
  };

  axios
    .get(url, {
      params: data,
    })
    .then((response) => {
      console.log(`Status: ${response.status}`);
      return response;
    })
    .catch((error) => {
      console.error(error);
    });
};
export default message;
