/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';

// @mui
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete } from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function FeedbackCreate({ resetPage }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');

  const [options, setOptions] = useState([]);

  const [selectedTypeId, setSelectedTypeId] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const fetchTypes = async () => {
    const response = await API.get('/report/type');
    const {
      data: { data },
    } = response;
    setOptions(data);
  };

  useEffect(() => {
    fetchTypes();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const {
        data: { success },
      } = await API.post(`/crime/type`, {
        c_sub_type_name: name,
        type_id: selectedTypeId,
      });
      if (success === 1) {
        resetPage();
        setName('');
        handleClose();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose} sx={{ minWidth: '650px' }}>
        <DialogTitle>Гэмт хэргийн төрөл нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Autocomplete
            disablePortal
            id="category-box"
            options={options.map((data) => ({ title: data.type_name, id: data.type_id }))}
            isOptionEqualToValue={(option, value) => option.title === value.title}
            getOptionLabel={(option) => option.title}
            sx={{ width: 1, mb: 2 }}
            onChange={(_, value) => setSelectedTypeId(value.id)}
            renderInput={(params) => <TextField {...params} label="Төрөл сонгох" />}
          />
          <TextField
            sx={{
              my: 2,
            }}
            value={name}
            onChange={(event) => setName(event.target.value)}
            getOptionLabel={(option) => option.type_name}
            label="Гэмт хэргийн төрөл"
            type="text"
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
