/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';

// @mui
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';

import API from '../../../api';

export default function ImageCreate({ resetPage, selectedFolder }) {
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [uploadFileNames, setUploadFileNames] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({});

  const handleFileNameChange = (value, index) => {
    const temp = uploadFileNames;
    temp[index] = value;
    setUploadFileNames(temp);
  };

  const uploadFiles = acceptedFiles.map((file, index) => (
    <div key={index}>
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
      <TextField
        label={`Нэр өгөх:`}
        value={uploadFileNames[index]}
        onChange={(e) => {
          handleFileNameChange(e.target.value, index);
        }}
        sx={{ mb: 2 }}
      />
    </div>
  ));

  const removeImages = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setUploadFileNames([]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploadFileNames([]);
  };

  const [folders, setFolders] = useState([]);

  const fetchFolders = async () => {
    const response = await API.get('/upload/search?file_type=folder&text=');
    const { data } = response;
    const { success, message, data: listData } = data;
    if (success === 1) {
      setFolders(listData);
    } else {
      toast.error(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  useEffect(() => {
    fetchFolders();
    console.log(selectedFolder);
  }, []);

  const handleSubmit = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const filesData = new FormData();

    let fileNames = '';

    acceptedFiles.forEach((imageFile, index) => {
      filesData.append('files', imageFile);
      fileNames = `${fileNames},"${uploadFileNames[index]}"`;
    });
    filesData.append('file_names', fileNames.substring(1));
    if (selectedFolder && selectedFolder.file_id) {
      console.log(selectedFolder);
      filesData.append('folder_id', selectedFolder.file_id);
    }

    try {
      setLoading(true);

      const response = await API.post(`/upload`, filesData, config);
      const {
        data: { success, message },
      } = response;

      if (success === 1) {
        resetPage();
        handleClose();
        removeImages();
        toast.success('Амжилттай нэмэгдлээ', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Хүсэлт амжилтгүй ${error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleClickOpen}>
        Нэмэх
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Файлууд нэмэх</DialogTitle>
        <DialogContent
          sx={{
            py: 1,
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Box sx={{ borderRadius: 1, border: 'dotted', borderColor: 'grey.500', p: 2, textAlign: 'center' }}>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>Файлыг энд чирж, эсвэл энд товшино уу. (Олон файл зэрэг оруулж болно.)</p>
                  <em>(*Зураг төрөлтэй файлууд автоматаар зургийн санд орохыг анхаарна уу.)</em>
                </div>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <aside>
                <Typography variant="h5" mx={2} gutterBottom>
                  Файлууд:
                </Typography>
                {uploadFiles.length > 0 && <Box sx={{ m: 2 }}>{uploadFiles}</Box>}
              </aside>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Цуцлах</Button>
          {loading ? 'loading' : <Button onClick={handleSubmit}>Хадгалах</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
}
