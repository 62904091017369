import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import { Stack, IconButton, InputAdornment, TextField, CircularProgress, Box, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// service
import API from '../../../api';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function ResetForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [password, setPassword] = useState('');
  const [repassword, setRePassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleClick = async () => {
    if (!password) {
      toast.error(`Одоогийн нууц үг оруулна уу`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (newPassword !== repassword) {
      toast.error(`Шинэ нууц үг адилхан оруулна уу`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    try {
      setLoading(true);
      const response = await API.put('/auth/changePassword', {
        password,
        newPassword,
      });
      const {
        data: { success, message },
      } = response;
      if (success === true) {
        toast.success(`${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        navigate('/dashboard', { replace: true });
      } else {
        toast.error(`Хүсэлт амжилтгүй ${message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      const {
        response: {
          data: { message: errmsg },
        },
      } = error;
      toast.error(`Хүсэлт амжилтгүй. ${errmsg}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="password"
          label="Одоогийн нууц үг"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="New password"
          label="Шинэ нууц үг"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
          type={showNewPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                  <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          name="Retry password"
          label="Шинэ нууц үг дахин оруулна уу."
          value={repassword}
          onChange={(event) => setRePassword(event.target.value)}
          type={showRePassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowRePassword(!showRePassword)} edge="end">
                  <Iconify icon={showRePassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link href="/forgot" variant="subtitle2" underline="hover">
          Нууц үг мартсан?
        </Link>
      </Stack> */}
      {loading ? (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      ) : (
        <LoadingButton
          sx={{
            mt: 3,
            backgroundColor: '#27AE60',
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          onClick={handleClick}
        >
          Хүсэлт илгээх
        </LoadingButton>
      )}
      <Button sx={{ mt: 4, backgroundColor: '#27AE60' }} to="/" size="large" variant="contained" component={RouterLink}>
        Буцах
      </Button>
    </>
  );
}
