import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Toolbar, Autocomplete, TextField, Button, IconButton, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// ----------------------------------------------------------------------
import Iconify from '../../../components/iconify';

import API from '../../../api';

// eslint-disable-next-line no-unused-vars
const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

// ----------------------------------------------------------------------

RequestListToolbar.propTypes = {
  onSelectCategory: PropTypes.func,
  filterSearch: PropTypes.func,
  categories: PropTypes.array,
  resetPage: PropTypes.func,
};

export default function RequestListToolbar({ categories, filterSearch, resetPage }) {
  const [subCategories, setSubCategories] = useState([]);
  const [users, setUsers] = useState([]);

  const [title, setTitle] = useState('');
  const [newsId, setNewsId] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(dayjs());

  const onSelectCategory = (data) => {
    const { sub_cat_id, sub_cat_name } = data;
    const subIds = sub_cat_id.split(',');
    const subNames = sub_cat_name.split(',');
    const list = subIds.map((id, index) => ({ sub_cat_id: id, sub_cat_name: subNames[index] }));
    setSubCategories(list);
    setSelectedCategory(data);
  };

  const fetchUsers = async () => {
    const response = await API.get('/auth/me');
    const {
      data: { data },
    } = response;
    setUsers(data);
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const onSelectSubCategory = (data) => {
    setSelectedSubCategory(data);
  };

  const handleClick = () => {
    filterSearch({
      title,
      newsId,
      selectedUser,
      startDate,
      endDate,
      selectedCategory,
      selectedSubCategory,
    });
  };

  const handleClear = () => {
    setTitle('');
    setNewsId('');
    setSelectedUser(null);
    setSelectedCategory(null);
    setSelectedSubCategory(null);
    setStartDate(undefined);
    setEndDate(dayjs());
    setSubCategories([]);
    resetPage();
  };

  return (
    <>
      <Grid container spacing={1} p={2}>
        <Grid item xs={4}>
          <TextField
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Гарчиг"
            type="text"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            value={newsId}
            onChange={(event) => setNewsId(event.target.value)}
            label="ID"
            type="text"
            fullWidth
            autoFocus
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            sx={{
              width: '100%',
            }}
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            label="Эхлэх огноо"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            sx={{
              width: '100%',
            }}
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            label="Дуусах огноо"
            type="text"
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} px={2} mb={2}>
        <Grid item xs={4}>
          <Autocomplete
            disablePortal
            disableClearable
            id="user-box"
            isOptionEqualToValue={(option, value) => option.a_id === value.a_id}
            getOptionLabel={(option) => option.a_username}
            options={users || []}
            value={selectedUser}
            onChange={(_event, value) => setSelectedUser(value)}
            renderInput={(params) => <TextField {...params} label="Хэрэглэгч" />}
            sx={{
              width: '100%',
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            disableClearable
            id="category-box"
            isOptionEqualToValue={(option, value) => option.cat_id === value.cat_id}
            getOptionLabel={(option) => option.cat_name}
            options={categories || []}
            value={selectedCategory || null}
            onChange={(_event, value) => onSelectCategory(value)}
            renderInput={(params) => <TextField {...params} label="Категори сонгох" />}
            sx={{
              width: '100%',
            }}
          />
        </Grid>
        <Grid item xs={3}>
          {subCategories?.length > 0 && (
            <Autocomplete
              disablePortal
              disableClearable
              id="sub-category-box"
              isOptionEqualToValue={(option, value) => option.sub_cat_id === value.sub_cat_id}
              getOptionLabel={(option) => option.sub_cat_name}
              options={subCategories}
              sx={{ width: 1, mb: 1 }}
              value={selectedSubCategory || null}
              onChange={(_event, value) => onSelectSubCategory(value)}
              renderInput={(params) => <TextField {...params} label="Дэд категори сонгох" />}
            />
          )}
        </Grid>
        <Grid
          item
          xs={2}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            size="large"
            variant="contained"
            startIcon={<Iconify icon="ic:baseline-search" />}
            onClick={() => handleClick()}
          >
            Хайх
          </Button>

          <IconButton size="large" onClick={() => handleClear()} sx={{ ml: 1 }}>
            <Iconify icon="material-symbols:delete" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}
